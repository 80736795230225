//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//reset constant
export const RESET = "RESET";

//loading constant
export const LOAD_PAGE = "LOAD_PAGE";
export const LOAD_AUTH_PAGE = "LOAD_AUTH_PAGE";
export const INITIAL_LOADING = "INITIAL_LOADING";
export const LOADING_ON_SUBMIT = "LOADING_ON_SUBMIT";
export const LOADING_ON_CMS_SUBMIT = "LOADING_ON_CMS_SUBMIT";
export const LOADING_ON_EMAIL_SUBMIT = "LOADING_ON_EMAIL_SUBMIT";
export const LOADING_ON_INQUIRY_SUBMIT = "LOADING_ON_INQUIRY_SUBMIT";
export const LOADING_ON_SETTINGS_SUBMIT = "LOADING_ON_SETTINGS_SUBMIT";
export const LOADING_ON_PROFILE_SUBMIT = "LOADING_ON_PROFILE_SUBMIT";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_USER_SUBMIT";
export const LOADING_ON_CHANGING_PASSWORD = "LOADING_ON_CHANGING_PASSWORD";
export const LOADING_ON_CITY_SUBMIT = "LOADING_ON_CITY_SUBMIT";
export const LOADING_ON_STAFF_SUBMIT = "LOADING_ON_STAFF_SUBMIT";
export const LOADING_ON_PRODUCT_SUBMIT = "LOADING_ON_PRODUCT_SUBMIT";
export const LOADING_ON_GROUP_TEST_SUBMIT = "LOADING_ON_GROUP_TEST_SUBMIT";
export const LOADING_ON_HABIT_SUBMIT = "LOADING_ON_SUBMIT_SUBMIT";
export const LOADING_ON_SAMPLE_TYPE_SUBMIT = "LOADING_ON_SAMPLE_TYPE_SUBMIT";
export const LOADING_ON_LAB_SUBMIT = "LOADING_ON_LAB_SUBMIT";
export const LOADING_ON_SPECIALITY_SUBMIT = "LOADING_ON_SPECIALITY_SUBMIT";
export const LOADING_ON_DOCTOR_SUBMIT = "LOADING_ON_DOCTOR_SUBMIT";
export const LOADING_ON_CUSTOMER_SUBMIT = "LOADING_ON_CUSTOMER_SUBMIT";
export const LOADING_ON_PHLEBOTOMIST_SUBMIT = "LOADING_ON_PHLEBOTOMIST_SUBMIT";
export const LOADING_ON_LOGIN_SUBMIT = "LOADING_ON_LOGIN_SUBMIT";
export const LOADING_ON_MARK_PAID = "LOADING_ON_MARK_PAID";
export const LOADING_LAB_MAX_OUTSTANDING = "LOADING_LAB_MAX_OUTSTANDING";
export const LOADING_DOCTOR_MAX_OUTSTANDING = "LOADING_DOCTOR_MAX_OUTSTANDING";
export const LOADING_PHLEBOTOMIST_MAX_OUTSTANDING =
  "LOADING_PHLEBOTOMIST_MAX_OUTSTANDING";
export const LOADING_ON_ARTICLE_SUBMIT = "LOADING_ON_ARTICLE_SUBMIT";
export const LOADING_ON_EMAIL_VERIFICATION = "LOADING_ON_EMAIL_VERIFICATION";
export const LOADING_ON_COUPON_SUBMIT = "LOADING_ON_COUPON_SUBMIT";
export const LOADING_ON_ORDER_SUBMIT = "LOADING_ON_ORDER_SUBMIT";
export const LOADING_ON_CUSTOMER_ADDRESS_SUBMIT =
  "LOADING_ON_CUSTOMER_ADDRESS_SUBMIT";
export const LOADING_ON_CUSTOMER_PATIENT_SUBMIT =
  "LOADING_ON_CUSTOMER_PATIENT_SUBMIT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const LOGIN_OTP_SENT_SUCCESS = "LOGIN_OTP_SENT_SUCCESS";
export const ENABLE_RESEND_OTP_OPTION = "ENABLE_RESEND_OTP_OPTION";

export const LOADING_CMS_LIST_DATA = "LOADING_CMS_LIST_DATA";
export const LOADING_EMAIL_LIST_DATA = "LOADING_EMAIL_LIST_DATA";
export const LOADING_INQUIRY_LIST_DATA = "LOADING_INQUIRY_LIST_DATA";
export const LOADING_CITY_LIST_DATA = "LOADING_CITY_LIST_DATA";
export const LOADING_STAFF_LIST_DATA = "LOADING_STAFF_LIST_DATA";
export const LOADING_LAB_LIST_DATA = "LOADING_LAB_LIST_DATA";
export const LOADING_PRODUCT_LIST_DATA = "LOADING_PRODUCT_LIST_DATA";
export const LOADING_SPECIALITY_LIST_DATA = "LOADING_SPECIALITY_LIST_DATA";
export const LOADING_HABIT_LIST_DATA = "LOADING_HABIT_LIST_DATA";
export const LOADING_GROUP_TEST_LIST_DATA = "LOADING_GROUP_TEST_LIST_DATA";
export const LOADING_SAMPLE_TYPE_LIST_DATA = "LOADING_SAMPLE_TYPE_LIST_DATA";
export const LOADING_DOCTOR_LIST_DATA = "LOADING_DOCTOR_LIST_DATA";
export const LOADING_CUSTOMER_LIST_DATA = "LOADING_CUSTOMER_LIST_DATA";
export const LOADING_LAB_REVIEW_LIST_DATA = "LOADING_LAB_REVIEW_LIST_DATA";
export const LOADING_LAB_PAYMENT_LIST_DATA = "LOADING_LAB_PAYMENT_LIST_DATA";
export const LOADING_DOCTOR_PAYMENT_LIST_DATA =
  "LOADING_DOCTOR_PAYMENT_LIST_DATA";
export const LOADING_PHLEBOTOMIST_REVIEW_LIST_DATA =
  "LOADING_PHLEBOTOMIST_REVIEW_LIST_DATA";
export const LOADING_PHLEBOTOMIST_PAYMENT_LIST_DATA =
  "LOADING_PHLEBOTOMIST_PAYMENT_LIST_DATA";
export const LOADING_ARTICLE_LIST_DATA = "LOADING_ARTICLE_LIST_DATA";
export const LOADING_COUPON_LIST_DATA = "LOADING_COUPON_LIST_DATA";
export const LOADING_ORDER_LIST_DATA = "LOADING_ORDER_LIST_DATA";

export const LOADING_ON_PASSWORD_RESET = "LOADING_ON_PASSWORD_RESET";
export const LOADING_TEST_CATEGORIES = "LOADING_TEST_CATEGORIES";
export const LOADING_CERTIFICATE_CATEGORIES = "LOADING_CERTIFICATE_CATEGORIES";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

//login/logout/register, auth, profile constnats
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const ACCOUNT_DELETED = "ACCOUNT_DELETED";

export const USER_LOADED = "USER_LOADED";
export const USER_AUTHENTICATED = "USER_AUTHENTICATED";
export const AUTH_TOKEN_REFRESH = "AUTH_TOKEN_REFRESH";

export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const AUTH_ERROR = "AUTH_ERROR";
export const PENDING_ACTION_LOADED = "PENDING_ACTION_LOADED";

// Verification Constants
export const VERIFICATION_LINK_SUCCESS = "VERIFICATION_LINK_SUCCESS";
export const VERIFICATION_LINK_FAIL = "VERIFICATION_LINK_FAIL";
export const AUTH_LOAD_PAGE = "AUTH_LOAD_PAGE";

// Articles Constants
export const ARTICLE_CREATED = "ARTICLE_CREATED";
export const ARTICLE_ERROR = "ARTICLE_ERROR";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const ARTICLE_UPDATED = "ARTICLE_UPDATED";
export const ARTICLE_LIST_UPDATED = "ARTICLE_LIST_UPDATED";
export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID";
export const CHANGE_ARTICLE_STATUS = "CHANGE_ARTICLE_STATUS";
export const ARTICLE_SEARCH_PARAMATERS_UPDATE =
  "ARTICLE_SEARCH_PARAMATERS_UPDATE";

// Customer Address Constants
export const CUSTOMER_ADDRESS_CREATED = "CUSTOMER_ADDRESS_CREATED";
export const CUSTOMER_ADDRESS_ERROR = "CUSTOMER_ADDRESS_ERROR";
export const CUSTOMER_ADDRESS_LIST_UPDATED = "CUSTOMER_ADDRESS_LIST_UPDATED";
export const CUSTOMER_ADDRESS_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_ADDRESS_SEARCH_PARAMATERS_UPDATE";

// Customer Patient Constants
export const CUSTOMER_PATIENT_CREATED = "CUSTOMER_PATIENT_CREATED";
export const CUSTOMER_PATIENT_ERROR = "CUSTOMER_PATIENT_ERROR";
export const CUSTOMER_PATIENT_LIST_UPDATED = "CUSTOMER_PATIENT_LIST_UPDATED";
export const CUSTOMER_PATIENT_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_PATIENT_SEARCH_PARAMATERS_UPDATE";

//Users constants
export const USER_CREATED = "USER_CREATED";
export const USER_ERROR = "USER_CREATE_ERROR";
export const DELETE_USER = "DELETE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LIST_UPDATED = "USER_LIST_UPDATED";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const USER_SEARCH_PARAMATERS_UPDATE = "USER_SEARCH_PARAMATERS_UPDATE";
export const USER_PROFILE_PASSWORD_ERROR = "USER_PROFILE_PASSWORD_ERROR";
export const USER_PROFILE_PASSWORD_UPDATED = "USER_PROFILE_PASSWORD_UPDATED";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";

//Email Template Constants
export const EMAIL_TEMPLATE_CREATED = "EMAIL_TEMPLATE_CREATED";
export const EMAIL_TEMPLATE_ERROR = "EMAIL_TEMPLATE_ERROR";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_UPDATED = "EMAIL_TEMPLATE_UPDATED";
export const EMAIL_TEMPLATE_LIST_UPDATED = "EMAIL_TEMPLATE_LIST_UPDATED";
export const GET_EMAIL_TEMPLATE_BY_ID = "GET_EMAIL_TEMPLATE_BY_ID";
export const CHANGE_EMAIL_TEMPLATE_STATUS = "CHANGE_EMAIL_TEMPLATE_STATUS";
export const EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE =
  "EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE";

//CMS Constants
export const CMS_CREATED = "CMS_CREATED";
export const CMS_ERROR = "CMS_ERROR";
export const DELETE_CMS = "DELETE_CMS";
export const CMS_UPDATED = "CMS_UPDATED";
export const CMS_LIST_UPDATED = "CMS_LIST_UPDATED";
export const GET_CMS_BY_ID = "GET_CMS_BY_ID";
export const CHANGE_CMS_STATUS = "CHANGE_CMS_STATUS";
export const CMS_SEARCH_PARAMATERS_UPDATE = "CMS_SEARCH_PARAMATERS_UPDATE";

//Coupon Constants
export const COUPON_CREATED = "COUPON_CREATED";
export const COUPON_ERROR = "COUPON_ERROR";
export const COUPON_DELETE = "COUPON_DELETE";
export const COUPON_UPDATED = "COUPON_UPDATED";
export const COUPON_LIST_UPDATED = "COUPON_LIST_UPDATED";
export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID";
export const CHANGE_COUPON_STATUS = "CHANGE_COUPON_STATUS";
export const COUPON_SEARCH_PARAMATERS_UPDATE =
  "COUPON_SEARCH_PARAMATERS_UPDATE";

//Inquiry Constants
export const INQUIRY_CREATED = "INQUIRY_CREATED";
export const INQUIRY_ERROR = "INQUIRY_ERROR";
export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const INQUIRY_UPDATED = "INQUIRY_UPDATED";
export const INQUIRY_LIST_UPDATED = "INQUIRY_LIST_UPDATED";
export const GET_INQUIRY_BY_ID = "GET_INQUIRY_BY_ID";
export const CHANGE_INQUIRY_STATUS = "CHANGE_INQUIRY_STATUS";
export const INQUIRY_SEARCH_PARAMATERS_UPDATE =
  "INQUIRY_SEARCH_PARAMATERS_UPDATE";

//Admin Staff Constants
export const STAFF_CREATED = "STAFF_CREATED";
export const STAFF_ERROR = "STAFF_ERROR";
export const STAFF_DELETE = "STAFF_DELETE";
export const STAFF_UPDATED = "STAFF_UPDATED";
export const STAFF_LIST_UPDATED = "STAFF_LIST_UPDATED";
export const GET_STAFF_BY_ID = "GET_STAFF_BY_ID";
export const CHANGE_STAFF_STATUS = "CHANGE_STAFF_STATUS";
export const STAFF_SEARCH_PARAMATERS_UPDATE = "STAFF_SEARCH_PARAMATERS_UPDATE";

//Super Admin Constants
export const SUPER_ADMIN_CREATED = "SUPER_ADMIN_CREATED";
export const SUPER_ADMIN_ERROR = "SUPER_ADMIN_ERROR";
export const SUPER_ADMIN_DELETE = "SUPER_ADMIN_DELETE";
export const SUPER_ADMIN_UPDATED = "SUPER_ADMIN_UPDATED";
export const SUPER_ADMIN_LIST_UPDATED = "SUPER_ADMIN_LIST_UPDATED";
export const GET_SUPER_ADMIN_BY_ID = "GET_SUPER_ADMIN_BY_ID";
export const CHANGE_SUPER_ADMIN_STATUS = "CHANGE_SUPER_ADMIN_STATUS";
export const SUPER_ADMIN_SEARCH_PARAMATERS_UPDATE =
  "SUPER_ADMIN_SEARCH_PARAMATERS_UPDATE";
export const LOADING_SUPER_ADMIN_LIST_DATA = "LOADING_SUPER_ADMIN_LIST_DATA";
export const LOADING_ON_SUPER_ADMIN_SUBMIT = "LOADING_ON_SUPER_ADMIN_SUBMIT";
export const SUPER_ADMIN_PERMISSIONS_UPDATED =
  "SUPER_ADMIN_PERMISSIONS_UPDATED";

// Lab Tests Constants Start //

// Test Constants
export const PRODUCT_CREATED = "PRODUCT_CREATED";
export const PRODUCT_ERROR = "PRODUCT_ERROR";
export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_UPDATED = "PRODUCT_UPDATED";
export const PRODUCT_LIST_UPDATED = "PRODUCT_LIST_UPDATED";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const PRODUCT_SEARCH_PARAMATERS_UPDATE =
  "PRODUCT_SEARCH_PARAMATERS_UPDATE";
export const UPDATE_DRUG_TEST_CUTOFF = "UPDATE_DRUG_TEST_CUTOFF";

// Group Test Constants
export const GROUP_TEST_CREATED = "GROUP_TEST_CREATED";
export const GROUP_TEST_ERROR = "GROUP_TEST_ERROR";
export const GROUP_TEST_DELETE = "GROUP_TEST_DELETE";
export const GROUP_TEST_UPDATED = "GROUP_TEST_UPDATED";
export const GROUP_TEST_LIST_UPDATED = "GROUP_TEST_LIST_UPDATED";
export const GET_GROUP_TEST_BY_ID = "GET_GROUP_TEST_BY_ID";
export const CHANGE_GROUP_TEST_STATUS = "CHANGE_GROUP_TEST_STATUS";
export const GROUP_TEST_SEARCH_PARAMATERS_UPDATE =
  "GROUP_TEST_SEARCH_PARAMATERS_UPDATE";

// Habit Test Constants
export const HABIT_CREATED = "HABIT_CREATED";
export const HABIT_ERROR = "HABIT_ERROR";
export const HABIT_DELETE = "HABIT_DELETE";
export const HABIT_UPDATED = "HABIT_UPDATED";
export const HABIT_LIST_UPDATED = "HABIT_LIST_UPDATED";
export const GET_HABIT_BY_ID = "GET_HABIT_BY_ID";
export const CHANGE_HABIT_STATUS = "CHANGE_HABIT_STATUS";
export const HABIT_SEARCH_PARAMATERS_UPDATE = "HABIT_SEARCH_PARAMATERS_UPDATE";

// Sample Type Constants
export const SAMPLE_TYPE_CREATED = "SAMPLE_TYPE_CREATED";
export const SAMPLE_TYPE_ERROR = "SAMPLE_TYPE_ERROR";
export const SAMPLE_TYPE_DELETE = "SAMPLE_TYPE_DELETE";
export const SAMPLE_TYPE_UPDATED = "SAMPLE_TYPE_UPDATED";
export const SAMPLE_TYPE_LIST_UPDATED = "SAMPLE_TYPE_LIST_UPDATED";
export const GET_SAMPLE_TYPE_BY_ID = "GET_SAMPLE_TYPE_BY_ID";
export const CHANGE_SAMPLE_TYPE_STATUS = "CHANGE_SAMPLE_TYPE_STATUS";
export const SAMPLE_TYPE_SEARCH_PARAMATERS_UPDATE =
  "SAMPLE_TYPE_SEARCH_PARAMATERS_UPDATE";

// Lab Tests Provided Constants
export const LAB_TESTS_PROVIDED_CREATED = "LAB_TESTS_PROVIDED_CREATED";
export const LAB_TESTS_PROVIDED_IMPORTED = "LAB_TESTS_PROVIDED_IMPORTED";
export const LAB_TESTS_PROVIDED_ERROR = "LAB_TESTS_PROVIDED_ERROR";
export const CHANGE_LAB_TESTS_PROVIDED_STATUS =
  "CHANGE_LAB_TESTS_PROVIDED_STATUS";
export const LAB_TESTS_PROVIDED_LIST_UPDATED =
  "LAB_TESTS_PROVIDED_LIST_UPDATED";
export const LAB_TESTS_PROVIDED_SEARCH_PARAMATERS_UPDATE =
  "LAB_TESTS_PROVIDED_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_LAB_TESTS_PROVIDED_SUBMIT =
  "LOADING_ON_LAB_TESTS_PROVIDED_SUBMIT";
export const LOADING_LAB_TESTS_PROVIDED_LIST_DATA =
  "LOADING_LAB_TESTS_PROVIDED_LIST_DATA";

// Lab Groups Provided Constants
export const LAB_GROUPS_PROVIDED_IMPORTED = "LAB_GROUPS_PROVIDED_IMPORTED";
export const LAB_GROUPS_PROVIDED_ERROR = "LAB_GROUPS_PROVIDED_ERROR";
export const LAB_GROUPS_PROVIDED_LIST_UPDATED =
  "LAB_GROUPS_PROVIDED_LIST_UPDATED";
export const LAB_GROUPS_PROVIDED_SEARCH_PARAMATERS_UPDATE =
  "LAB_GROUPS_PROVIDED_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_LAB_GROUPS_PROVIDED_SUBMIT =
  "LOADING_ON_LAB_GROUPS_PROVIDED_SUBMIT";
export const LOADING_LAB_GROUPS_PROVIDED_LIST_DATA =
  "LOADING_LAB_GROUPS_PROVIDED_LIST_DATA";

//Tests And Groups Constants
export const TEST_AND_GROUP_EXPORTED = "TEST_AND_GROUP_EXPORTED";
export const TEST_AND_GROUP_ERROR = "TEST_AND_GROUP_ERROR";
export const TEST_AND_GROUP_LIST_UPDATED = "TEST_AND_GROUP_LIST_UPDATED";
export const TEST_AND_GROUP_SEARCH_PARAMATERS_UPDATE =
  "TEST_AND_GROUP_SEARCH_PARAMATERS_UPDATE";
export const LOADING_TEST_AND_GROUP_LIST_DATA =
  "LOADING_TEST_AND_GROUP_LIST_DATA";
export const LOADING_TEST_AND_GROUP_ON_EXPORT =
  "LOADING_TEST_AND_GROUP_ON_EXPORT";

//Possible Results
export const POSSIBLE_RESULT_CREATED = "POSSIBLE_RESULT_CREATED";
export const POSSIBLE_RESULT_ERROR = "POSSIBLE_RESULT_ERROR";
export const POSSIBLE_RESULT_DELETE = "POSSIBLE_RESULT_DELETE";
export const POSSIBLE_RESULT_UPDATED = "POSSIBLE_RESULT_UPDATED";
export const POSSIBLE_RESULT_LIST_UPDATE = "POSSIBLE_RESULT_LIST_UPDATE";
export const GET_POSSIBLE_RESULT_BY_ID = "GET_POSSIBLE_RESULT_BY_ID";
export const POSSIBLE_RESULTS_SEARCH_PARAMATERS_UPDATE =
  "POSSIBLE_RESULTS_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_POSSIBLE_RESULT_SUBMIT =
  "LOADING_ON_POSSIBLE_RESULT_SUBMIT";
export const LOADING_POSSIBLE_RESULTS_LIST_DATA =
  "LOADING_POSSIBLE_RESULTS_LIST_DATA";
export const UPDATE_POSSIBLE_RESULTS_LIST = "UPDATE_POSSIBLE_RESULTS_LIST";
export const UPDATE_CURRENT_TEST = "UPDATE_CURRENT_TEST";

// Lab Review Constants
export const LAB_REVIEW_ERROR = "LAB_REVIEW_ERROR";
export const LAB_REVIEW_LIST_UPDATED = "LAB_REVIEW_LIST_UPDATED";
export const LAB_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "LAB_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LAB_REVIEW_STATUS = "CHANGE_LAB_REVIEW_STATUS";

// Lab Payment Constants
export const LAB_MAX_OUTSTANDING = "LAB_MAX_OUTSTANDING";
export const LAB_PAYMENT_CREATED = "LAB_PAYMENT_CREATED";
export const LAB_PAYMENT_ERROR = "LAB_PAYMENT_ERROR";
export const LAB_PAYMENT_LIST_UPDATED = "LAB_PAYMENT_LIST_UPDATED";
export const LAB_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "LAB_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_LAB_PAYMENT_STATUS = "CHANGE_LAB_PAYMENT_STATUS";

// Ledger Constants
export const LEDGER_ERROR = "LEDGER_ERROR";
export const LEDGER_LIST_UPDATED = "LEDGER_LIST_UPDATED";
export const LEDGER_SEARCH_PARAMATERS_UPDATE =
  "LEDGER_SEARCH_PARAMATERS_UPDATE";
export const LOADING_LEDGER_LIST_DATA = "LOADING_LEDGER_LIST_DATA";

// Doctors Constants Start //

// Speciality Constants
export const SPECIALITY_CREATED = "SPECIALITY_CREATED";
export const SPECIALITY_ERROR = "SPECIALITY_ERROR";
export const SPECIALITY_DELETE = "SPECIALITY_DELETE";
export const SPECIALITY_UPDATED = "SPECIALITY_UPDATED";
export const SPECIALITY_LIST_UPDATED = "SPECIALITY_LIST_UPDATED";
export const GET_SPECIALITY_BY_ID = "GET_SPECIALITY_BY_ID";
export const CHANGE_SPECIALITY_STATUS = "CHANGE_SPECIALITY_STATUS";
export const SPECIALITY_SEARCH_PARAMATERS_UPDATE =
  "SPECIALITY_SEARCH_PARAMATERS_UPDATE";

// Doctors Constants
export const DOCTOR_CREATED = "DOCTOR_CREATED";
export const DOCTOR_ERROR = "DOCTOR_ERROR";
export const DOCTOR_DELETE = "DOCTOR_DELETE";
export const DOCTOR_UPDATED = "DOCTOR_UPDATED";
export const DOCTOR_LIST_UPDATED = "DOCTOR_LIST_UPDATED";
export const GET_DOCTOR_BY_ID = "GET_DOCTOR_BY_ID";
export const CHANGE_DOCTOR_STATUS = "CHANGE_DOCTOR_STATUS";
export const DOCTOR_SEARCH_PARAMATERS_UPDATE =
  "DOCTOR_SEARCH_PARAMATERS_UPDATE";

// Doctor Payment Constants

export const DOCTOR_MAX_OUTSTANDING = "DOCTOR_MAX_OUTSTANDING";
export const DOCTOR_PAYMENT_CREATED = "DOCTOR_PAYMENT_CREATED";
export const DOCTOR_PAYMENT_ERROR = "DOCTOR_PAYMENT_ERROR";
export const DOCTOR_PAYMENT_LIST_UPDATED = "DOCTOR_PAYMENT_LIST_UPDATED";
export const DOCTOR_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "DOCTOR_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_DOCTOR_PAYMENT_STATUS = "CHANGE_DOCTOR_PAYMENT_STATUS";

// Doctors Constants End //

// Customer Constants
export const CUSTOMER_CREATED = "CUSTOMER_CREATED";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const CUSTOMER_LIST_UPDATED = "CUSTOMER_LIST_UPDATED";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const CHANGE_CUSTOMER_STATUS = "CHANGE_CUSTOMER_STATUS";
export const CUSTOMER_SEARCH_PARAMATERS_UPDATE =
  "CUSTOMER_SEARCH_PARAMATERS_UPDATE";

// Phlebotomist Start Constants //

export const PHLEBOTOMIST_CREATED = "PHLEBOTOMIST_CREATED";
export const PHLEBOTOMIST_ERROR = "PHLEBOTOMIST_ERROR";
export const PHLEBOTOMIST_DELETE = "PHLEBOTOMIST_DELETE";
export const PHLEBOTOMIST_UPDATED = "PHLEBOTOMIST_UPDATED";
export const PHLEBOTOMIST_LIST_UPDATED = "PHLEBOTOMIST_LIST_UPDATED";
export const GET_PHLEBOTOMIST_BY_ID = "GET_PHLEBOTOMIST_BY_ID";
export const CHANGE_PHLEBOTOMIST_STATUS = "CHANGE_PHLEBOTOMIST_STATUS";
export const PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_SEARCH_PARAMATERS_UPDATE";
export const PHLEBOTOMIST_CERTIFICATE_DELETE =
  "PHLEBOTOMIST_CERTIFICATE_DELETE";
export const LOADING_PHLEBOTOMIST_LIST_DATA = "LOADING_PHLEBOTOMIST_LIST_DATA";

// Phlebotomist Review Constants
export const PHLEBOTOMIST_REVIEW_ERROR = "PHLEBOTOMIST_REVIEW_ERROR";
export const PHLEBOTOMIST_REVIEW_LIST_UPDATED =
  "PHLEBOTOMIST_REVIEW_LIST_UPDATED";
export const PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_REVIEW_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_PHLEBOTOMIST_REVIEW_STATUS =
  "CHANGE_PHLEBOTOMIST_REVIEW_STATUS";

// Phlebotomist Payment Constants
export const PHLEBOTOMIST_MAX_OUTSTANDING = "PHLEBOTOMIST_MAX_OUTSTANDING";
export const PHLEBOTOMIST_PAYMENT_CREATED = "PHLEBOTOMIST_PAYMENT_CREATED";
export const PHLEBOTOMIST_PAYMENT_ERROR = "PHLEBOTOMIST_PAYMENT_ERROR";
export const PHLEBOTOMIST_PAYMENT_LIST_UPDATED =
  "PHLEBOTOMIST_PAYMENT_LIST_UPDATED";
export const PHLEBOTOMIST_PAYMENT_SEARCH_PARAMATERS_UPDATE =
  "PHLEBOTOMIST_PAYMENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_PHLEBOTOMIST_PAYMENT_STATUS = "CHANGE_LAB_PAYMENT_STATUS";

// Phlebotomist End Constants //

// Orders Constants //
export const ORDER_CREATED = "PHLEBOTOMIST_CREATED";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_UPDATED = "ORDER_UPDATED";
export const ORDER_CANCEL = "ORDER_CANCEL";
export const ORDER_HOLD = "ORDER_HOLD";
export const ORDER_LIST_UPDATED = "ORDER_LIST_UPDATED";
export const ORDER_PRESCRIPTION_APPROVED = "ORDER_PRESCRIPTION_APPROVED";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const ORDER_SEARCH_PARAMATERS_UPDATE = "ORDER_SEARCH_PARAMATERS_UPDATE";
export const ORDER_CERTIFICATE_DELETE = "ORDER_CERTIFICATE_DELETE";

//Log constant

export const LOG_ERROR = "LOG_ERROR";
export const LOG_LIST_UPDATED = "LOG_LIST_UPDATED";
export const GET_LOG_BY_ID = "GET_LOG_BY_ID";
export const LOG_SEARCH_PARAMATERS_UPDATE = "LOG_SEARCH_PARAMATERS_UPDATE";
export const LOADING_LOG_LIST_DATA = "LOADING_LOG_LIST_DATA";
export const LOADING_ON_LOG_SUBMIT = "LOADING_ON_LOG_SUBMIT";

// Setting Constants
export const SETTING_ERROR = "SETTING_ERROR";
export const SETTING_UPDATED = "SETTING_UPDATED";
export const GET_SETTING = "GET_SETTING";

// Profile Constants
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const INITIAL_MODAL_STATE = "INITIAL_MODAL_STATE";

// Forgot Password Constants
export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";

// Test Categories Constants
export const TEST_CATEGORIES_ERROR = "TEST_CATEGORIES_ERROR";
export const TEST_CATEGORIES_LIST_UPDATED = "TEST_CATEGORIES_LIST_UPDATED";

// Certificate Categories Constants
export const CERTIFICATE_CATEGORIES_ERROR = "CERTIFICATE_CATEGORIES_ERROR";
export const CERTIFICATE_CATEGORIES_LIST_UPDATED =
  "CERTIFICATE_CATEGORIES_LIST_UPDATED";

//Admin Lab Collection Center
export const COLLECTION_CENTER_RESET = "COLLECTION_CENTER_RESET";
export const COLLECTION_CENTER_LOAD_PAGE = "COLLECTION_CENTER_LOAD_PAGE";
export const COLLECTION_CENTER_LOADING_ON_SUBMIT =
  "COLLECTION_CENTER_LOADING_ON_SUBMIT";
export const COLLECTION_CENTER_CREATE = "COLLECTION_CENTER_CREATE";
export const COLLECTION_CENTER_ERROR = "COLLECTION_CENTER_ERROR";
export const COLLECTION_CENTER_LIST_LOADING = "COLLECTION_CENTER_LIST_LOADING";
export const COLLECTION_CENTER_LIST_UPDATED = "COLLECTION_CENTER_LIST_UPDATED";
export const COLLECTION_CENTER_DELETE = "COLLECTION_CENTER_DELETE";
export const COLLECTION_CENTER_GET_BY_ID = "COLLECTION_CENTER_GET_BY_ID";
export const COLLECTION_CENTER_UPDATED = "COLLECTION_CENTER_UPDATED";
export const COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE =
  "COLLECTION_CENTER_SEARCH_PARAMATERS_UPDATE";

// Admin Employers
export const EMPLOYER_CREATED = "EMPLOYER_CREATED";
export const EMPLOYER_ERROR = "EMPLOYER_ERROR";
export const EMPLOYER_LIST_UPDATED = "EMPLOYER_LIST_UPDATED";
export const EMPLOYER_SEARCH_PARAMATERS_UPDATE =
  "EMPLOYER_SEARCH_PARAMATERS_UPDATE";
export const LOADING_EMPLOYER_LIST_DATA = "LOADING_EMPLOYER_LIST_DATA";
export const EMPLOYER_DELETE = "EMPLOYER_DELETE";
export const EMPLOYER_UPDATED = "EMPLOYER_UPDATED";
export const GET_EMPLOYER_BY_ID = "GET_EMPLOYER_BY_ID";
export const CHANGE_EMPLOYER_STATUS = "CHANGE_EMPLOYER_STATUS";
export const LOADING_ON_EMPLOYER_SUBMIT = "LOADING_ON_EMPLOYER_SUBMIT";
export const EMPLOYER_EMPLOYEES_LIST_UPDATED =
  "EMPLOYER_EMPLOYEES_LIST_UPDATED";
export const LOADING_COMPANYADMIN_LIST = "LOADING_COMPANYADMIN_LIST";
export const ADMIN_COMPANYADMIN_LIST = "ADMIN_COMPANYADMIN_LIST";
export const EMPLOYER_ARCHIVED = "EMPLOYER_ARCHIVED";
export const EMPLOYER_BUSINESS_TYPES_UPDATED =
  "EMPLOYER_BUSINESS_TYPES_UPDATED";
export const ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO =
  "ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO";
export const ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED =
  "ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED";

// Admin EmployerAdmin
export const EMPLOYER_ADMIN_ERROR = "EMPLOYER_ADMIN_ERROR";
export const EMPLOYER_ADMIN_CREATED = "EMPLOYER_ADMIN_CREATED";
export const EMPLOYER_ADMIN_UPDATED = "EMPLOYER_ADMIN_UPDATED";
export const EMPLOYER_ADMIN_DELETE = "EMPLOYER_ADMIN_DELETE";
export const GET_EMPLOYER_ADMIN_BY_ID = "GET_EMPLOYER_ADMIN_BY_ID";
export const CHANGE_EMPLOYER_ADMIN_STATUS = "CHANGE_EMPLOYER_ADMIN_STATUS";
export const LOADING_EMPLOYER_IDS_LIST_DATA = "LOADING_EMPLOYER_IDS_LIST_DATA";
export const LOADING_ON_EMPLOYER_ADMIN_SUBMIT =
  "LOADING_ON_EMPLOYER_ADMIN_SUBMIT";
export const LOADING_EMPLOYER_ADMIN_LIST_DATA =
  "LOADING_EMPLOYER_ADMIN_LIST_DATA";
export const EMPLOYER_IDS_LIST_UPDATED = "EMPLOYER_IDS_LIST_UPDATED";
export const EMPLOYER_ADMIN_LIST_UPDATED = "EMPLOYER_ADMIN_LIST_UPDATED";
export const EMPLOYER_ADMIN_SEARCH_PARAMATERS_UPDATE =
  "EMPLOYER_ADMIN_SEARCH_PARAMATERS_UPDATE";
export const EMPLOYER_LIST_BY_COMPANY_ADMIN_UPDATED =
  "EMPLOYER_LIST_BY_COMPANY_ADMIN_UPDATED";

// Admin Patients
export const PATIENT_ERROR = "PATIENT_ERROR";
export const LOADING_PATIENT_BY_ID = "LOADING_PATIENT_BY_ID";
export const GET_PATIENT_BY_ID = "GET_PATIENT_BY_ID";

// Admin Lab constants
export const ADMIN_LAB_CREATED = "ADMIN_LAB_CREATED";
export const ADMIN_LAB_ERROR = "ADMIN_LAB_ERROR";
export const ADMIN_LAB_LIST_UPDATED = "ADMIN_LAB_LIST_UPDATED";
export const ADMIN_LAB_SEARCH_PARAMATERS_UPDATE =
  "ADMIN_LAB_SEARCH_PARAMATERS_UPDATE";
export const ADMIN_LAB_LOADING_LIST_DATA = "ADMIN_LAB_LOADING_LIST_DATA";
export const ADMIN_LAB_DELETE = "ADMIN_LAB_DELETE";
export const ADMIN_LAB_UPDATED = "ADMIN_LAB_UPDATED";
export const ADMIN_LAB_GET_BY_ID = "ADMIN_LAB_GET_BY_ID";
export const ADMIN_LAB_CHANGE_STATUS = "ADMIN_LAB_CHANGE_STATUS";
export const ADMIN_LAB_LOADING_ON_SUBMIT = "ADMIN_LAB_LOADING_ON_SUBMIT";

// Admin LabAdmin
export const LAB_ADMIN_ERROR = "LAB_ADMIN_ERROR";
export const LAB_ADMIN_CREATED = "LAB_ADMIN_CREATED";
export const LAB_ADMIN_UPDATED = "LAB_ADMIN_UPDATED";
export const LAB_ADMIN_DELETE = "LAB_ADMIN_DELETE";
export const GET_LAB_ADMIN_BY_ID = "GET_LAB_ADMIN_BY_ID";
export const CHANGE_LAB_ADMIN_STATUS = "CHANGE_LAB_ADMIN_STATUS";
export const LOADING_LAB_IDS_LIST_DATA = "LOADING_LAB_IDS_LIST_DATA";
export const LOADING_ON_LAB_ADMIN_SUBMIT = "LOADING_ON_LAB_ADMIN_SUBMIT";
export const LOADING_LAB_ADMIN_LIST_DATA = "LOADING_LAB_ADMIN_LIST_DATA";
export const LAB_IDS_LIST_UPDATED = "LAB_IDS_LIST_UPDATED";
export const LAB_ADMIN_LIST_UPDATED = "LAB_ADMIN_LIST_UPDATED";
export const LAB_ADMIN_SEARCH_PARAMATERS_UPDATE =
  "LAB_ADMIN_SEARCH_PARAMATERS_UPDATE";
export const LAB_LIST_BY_COMPANY_ADMIN_UPDATED =
  "LAB_LIST_BY_COMPANY_ADMIN_UPDATED";

// Admin test order
export const ADMIN_TEST_ORDER_CREATED = "ADMIN_TEST_ORDER_CREATED";
export const ADMIN_TEST_ORDER_ERROR = "ADMIN_TEST_ORDER_ERROR";
export const ADMIN_TEST_ORDER_LIST_UPDATED = "ADMIN_TEST_ORDER_LIST_UPDATED";
export const ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE =
  "ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE";
export const ADMIN_TEST_ORDER_LOADING_LIST_DATA =
  "ADMIN_TEST_ORDER_LOADING_LIST_DATA";
export const ADMIN_TEST_ORDER_DELETE = "ADMIN_TEST_ORDER_DELETE";
export const ADMIN_TEST_ORDER_UPDATED = "ADMIN_TEST_ORDER_UPDATED";
export const ADMIN_TEST_ORDER_GET_BY_ID = "ADMIN_TEST_ORDER_GET_BY_ID";
export const ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID =
  "ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID";
export const ADMIN_TEST_ORDER_CHANGE_STATUS = "ADMIN_TEST_ORDER_CHANGE_STATUS";
export const ADMIN_TEST_ORDER_LOADING_ON_SUBMIT =
  "ADMIN_TEST_ORDER_LOADING_ON_SUBMIT";
export const ADMIN_TEST_ORDER_EMPLOYERS_LIST =
  "ADMIN_TEST_ORDER_EMPLOYERS_LIST";
export const ADMIN_TEST_ORDER_EMPLOYEES_LIST =
  "ADMIN_TEST_ORDER_EMPLOYEES_LIST";
export const ADMIN_TEST_ORDER_STAFF_LIST = "ADMIN_TEST_ORDER_STAFF_LIST";
export const ADMIN_TEST_ORDER_PHYSICIAN_LIST =
  "ADMIN_TEST_ORDER_PHYSICIAN_LIST";
export const ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST =
  "ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST";
export const ADMIN_TEST_ORDER_NURSE_LIST = "ADMIN_TEST_ORDER_NURSE_LIST";
export const ADMIN_TEST_ORDER_LAB_LIST = "ADMIN_TEST_ORDER_LAB_LIST";
export const ADMIN_TEST_ORDER_CANCEL_SUBMIT = "ADMIN_TEST_ORDER_CANCEL_SUBMIT";
export const ADMIN_TEST_ORDER_CANCEL_COMPLETE =
  "ADMIN_TEST_ORDER_CANCEL_COMPLETE";
export const ADMIN_TEST_ORDER_PATIENT_DATA = "ADMIN_TEST_ORDER_PATIENT_DATA";
export const ADMIN_TEST_ORDER_BARCODE_ADDED = "ADMIN_TEST_ORDER_BARCODE_ADDED";
export const ADMIN_TEST_ORDER_ARCHIVED = "ADMIN_TEST_ORDER_ARCHIVED";
export const ADMIN_TEST_ORDER_VALIDATE_PATIENTS =
  "ADMIN_TEST_ORDER_VALIDATE_PATIENTS";
export const ADMIN_TEST_ORDER_BARCODING_STATUS =
  "ADMIN_TEST_ORDER_BARCODING_STATUS";
export const ADMIN_TEST_ORDER_EMPLOYER_LOCATIONS =
  "ADMIN_TEST_ORDER_EMPLOYER_LOCATIONS";
export const ADMIN_TEST_ORDER_STATES_LIST = "ADMIN_TEST_ORDER_STATES_LIST";
//
export const ADMIN_TEST_ORDER_ACTIVITY_LOGS_LOADING =
  "ADMIN_TEST_ORDER_ACTIVITY_LOGS_LOADING";
export const ADMIN_TEST_ORDER_ACTIVITY_LOGS = "ADMIN_TEST_ORDER_ACTIVITY_LOGS";
export const ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC =
  "ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC";
export const ADMIN_TEST_ORDER_CLIENT_LIST = "ADMIN_TEST_ORDER_CLIENT_LIST";

// Admin test order Notes
export const ADMIN_TEST_ORDER_NOTE_ERROR = "ADMIN_TEST_ORDER_NOTE_ERROR";
export const ADMIN_TEST_ORDER_NOTE_CREATED = "ADMIN_TEST_ORDER_NOTE_CREATED";
export const ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID =
  "ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID";
export const ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT =
  "ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT";

//Admin IRB section
export const IRB_ERROR = "IRB_ERROR";
export const IRB_DELETE = "IRB_DELETE";
export const IRB_LIST_UPDATED = "IRB_LIST_UPDATED";
export const IRB_SEARCH_PARAMATERS_UPDATE = "IRB_SEARCH_PARAMATERS_UPDATE";
export const GET_IRB_BY_ID = "GET_IRB_BY_ID";
export const IRB_UPDATED = "IRB_UPDATED";
export const LOADING_IRB_LIST_DATA = "LOADING_IRB_LIST_DATA";
export const LOADING_ON_IRB_SUBMIT = "LOADING_ON_IRB_SUBMIT";
export const IRB_NAMES_LIST_DATA = "IRB_NAMES_LIST_DATA";
export const IRB_NAMES_LIST_LOADING = "IRB_NAMES_LIST_LOADING";
export const IRB_CREATED = "IRB_CREATED";

// Admin repeat order
export const ADMIN_RECURRING_ORDER_CREATED = "ADMIN_RECURRING_ORDER_CREATED";
export const ADMIN_RECURRING_ORDER_ERROR = "ADMIN_RECURRING_ORDER_ERROR";
export const ADMIN_RECURRING_ORDER_LIST_UPDATED =
  "ADMIN_RECURRING_ORDER_LIST_UPDATED";
export const ADMIN_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE =
  "ADMIN_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE";
export const ADMIN_RECURRING_ORDER_LOADING_LIST_DATA =
  "ADMIN_RECURRING_ORDER_LOADING_LIST_DATA";
export const ADMIN_RECURRING_ORDER_DELETE = "ADMIN_RECURRING_ORDER_DELETE";
export const ADMIN_RECURRING_ORDER_UPDATED = "ADMIN_RECURRING_ORDER_UPDATED";
export const ADMIN_RECURRING_ORDER_GET_BY_ID =
  "ADMIN_RECURRING_ORDER_GET_BY_ID";
export const ADMIN_RECURRING_ORDER_CHANGE_STATUS =
  "ADMIN_RECURRING_ORDER_CHANGE_STATUS";
export const ADMIN_RECURRING_ORDER_LOADING_ON_SUBMIT =
  "ADMIN_RECURRING_ORDER_LOADING_ON_SUBMIT";
export const ADMIN_RECURRING_ORDER_CANCEL_SUBMIT =
  "ADMIN_RECURRING_ORDER_CANCEL_SUBMIT";
export const ADMIN_RECURRING_ORDER_CANCEL_COMPLETE =
  "ADMIN_RECURRING_ORDER_CANCEL_COMPLETE";

//Admin Physician Constants
export const PHYSICIAN_CREATED = "PHYSICIAN_CREATED";
export const PHYSICIAN_ERROR = "PHYSICIAN_ERROR";
export const PHYSICIAN_DELETE = "PHYSICIAN_DELETE";
export const PHYSICIAN_UPDATED = "PHYSICIAN_UPDATED";
export const PHYSICIAN_LIST_UPDATED = "PHYSICIAN_LIST_UPDATED";
export const GET_PHYSICIAN_BY_ID = "GET_PHYSICIAN_BY_ID";
export const CHANGE_PHYSICIAN_STATUS = "CHANGE_PHYSICIAN_STATUS";
export const PHYSICIAN_SEARCH_PARAMATERS_UPDATE =
  "PHYSICIAN_SEARCH_PARAMATERS_UPDATE";
export const LOADING_PHYSICIAN_LIST_DATA = "LOADING_PHYSICIAN_LIST_DATA";
export const LOADING_ON_PHYSICIAN_SUBMIT = "LOADING_ON_PHYSICIAN_SUBMIT";
export const PHYSICIAN_ORGANIZATION_REMOVED = "PHYSICIAN_ORGANIZATION_REMOVED";

//SMS Template Constants
export const SMS_TEMPLATE_CREATED = "SMS_TEMPLATE_CREATED";
export const SMS_TEMPLATE_ERROR = "SMS_TEMPLATE_ERROR";
export const DELETE_SMS_TEMPLATE = "DELETE_SMS_TEMPLATE";
export const SMS_TEMPLATE_UPDATED = "SMS_TEMPLATE_UPDATED";
export const SMS_TEMPLATE_LIST_UPDATED = "SMS_TEMPLATE_LIST_UPDATED";
export const GET_SMS_TEMPLATE_BY_ID = "GET_SMS_TEMPLATE_BY_ID";
export const CHANGE_SMS_TEMPLATE_STATUS = "CHANGE_SMS_TEMPLATE_STATUS";
export const SMS_TEMPLATE_SEARCH_PARAMATERS_UPDATE =
  "SMS_TEMPLATE_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_SMS_SUBMIT = "LOADING_ON_SMS_SUBMIT";
export const LOADING_SMS_LIST_DATA = "LOADING_SMS_LIST_DATA";

//Merge Patients
export const MERGE_PATIENTS_RESET = "MERGE_PATIENTS_RESET";
export const MERGE_PATIENTS_LOAD_PAGE = "MERGE_PATIENTS_LOAD_PAGE";
export const MERGE_PATIENTS_DETAILS = "MERGE_PATIENTS_DETAILS";
export const MERGE_PATIENTS_EMPLOYEES_LIST = "MERGE_PATIENTS_EMPLOYEES_LIST";
export const MERGE_PATIENTS_EMPLOYER_ERROR = "MERGE_PATIENTS_EMPLOYER_ERROR";
export const MERGE_PATIENTS_GET_EMPLOYER_BY_ID =
  "MERGE_PATIENTS_GET_EMPLOYER_BY_ID";
export const MERGE_PATIENTS_LOADING_EMPLOYER_LIST =
  "MERGE_PATIENTS_LOADING_EMPLOYER_LIST";
export const MERGE_PATIENTS_EMPLOYER_LIST_UPDATED =
  "MERGE_PATIENTS_EMPLOYER_LIST_UPDATED";
export const MERGE_PATIENTS_LOADING_ON_EMPLOYER_SUBMIT =
  "MERGE_PATIENTS_LOADING_ON_EMPLOYER_SUBMIT";
export const MERGE_PATIENTS_EMPLOYER_EMPLOYEES_LIST_UPDATED =
  "MERGE_PATIENTS_EMPLOYER_EMPLOYEES_LIST_UPDATED";

export const ADMIN_PARENT_COMPANY_LIST = "ADMIN_PARENT_COMPANY_LIST";

export const LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA =
  "LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA";
export const EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE =
  "EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE";
export const EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED =
  "EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED";

//Employmnet tests list
export const UPDATE_EMPLOYMENT_TESTS_LIST = "UPDATE_EMPLOYMENT_TESTS_LIST";

export const DRUG_TEST_CREATED = "DRUG_TEST_CREATED";
export const DRUG_TEST_ERROR = "DRUG_TEST_ERROR";
export const DRUG_TEST_UPDATED = "DRUG_TEST_UPDATED";
export const DRUG_TEST_DELETE = "DRUG_TEST_DELETE";
export const DRUG_TEST_LIST_UPDATED = "DRUG_TEST_LIST_UPDATED";
export const GET_DRUG_TEST_BY_ID = "GET_DRUG_TEST_BY_ID";
export const DRUG_TEST_SEARCH_PARAMATERS_UPDATE =
  "DRUG_TEST_SEARCH_PARAMATERS_UPDATE";
export const LOADING_ON_DRUG_TEST_SUBMIT = "LOADING_ON_DRUG_TEST_SUBMIT";
export const LOADING_DRUG_TEST_LIST_DATA = "LOADING_DRUG_TEST_LIST_DATA";

// Admin Dashboard
export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMIN_DASHBOARD_GET_DATA_COUNT = "ADMIN_DASHBOARD_GET_DATA_COUNT";

// Profile upload
export const USER_UPDATE_USER_PROFILE = "USER_UPDATE_USER_PROFILE";
export const USER_LOAD_USER_PROFILE = "USER_LOAD_USER_PROFILE";
export const USER_PROFILE_UPLOAD_ERROR = "USER_PROFILE_UPLOAD_ERROR";
export const USER_PROFILE_UPLOADING = "USER_PROFILE_UPLOADING";

// Common
export const UPDATE_BUSINESS_TYPES = "UPDATE_BUSINESS_TYPES";
export const UPDATE_BUSINESS_CATEGORIES = "UPDATE_BUSINESS_CATEGORIES";

// Admin Practice constants
export const ADMIN_PRACTICE_CREATED = "ADMIN_PRACTICE_CREATED";
export const ADMIN_PRACTICE_ERROR = "ADMIN_PRACTICE_ERROR";
export const ADMIN_PRACTICE_LIST_UPDATED = "ADMIN_PRACTICE_LIST_UPDATED";
export const ADMIN_PRACTICE_GET_NAMES_LIST = "ADMIN_PRACTICE_GET_NAMES_LIST";
export const ADMIN_PRACTICE_SEARCH_PARAMATERS_UPDATE =
  "ADMIN_PRACTICE_SEARCH_PARAMATERS_UPDATE";
export const ADMIN_PRACTICE_LOADING_LIST_DATA =
  "ADMIN_PRACTICE_LOADING_LIST_DATA";
export const ADMIN_PRACTICE_NAMES_LOADING_LIST_DATA =
  "ADMIN_PRACTICE_NAMES_LOADING_LIST_DATA";
export const ADMIN_PRACTICE_DELETE = "ADMIN_PRACTICE_DELETE";
export const ADMIN_PRACTICE_UPDATED = "ADMIN_PRACTICE_UPDATED";
export const ADMIN_PRACTICE_GET_BY_ID = "ADMIN_PRACTICE_GET_BY_ID";
export const ADMIN_PRACTICE_CHANGE_STATUS = "ADMIN_PRACTICE_CHANGE_STATUS";
export const ADMIN_PRACTICE_LOADING_ON_SUBMIT =
  "ADMIN_PRACTICE_LOADING_ON_SUBMIT";

// Admin Product Category constants
export const ADMIN_CATEGORY_CREATED = "ADMIN_CATEGORY_CREATED";
export const ADMIN_CATEGORY_ERROR = "ADMIN_CATEGORY_ERROR";
export const ADMIN_CATEGORY_LIST_UPDATED = "ADMIN_CATEGORY_LIST_UPDATED";
export const ADMIN_CATEGORY_SEARCH_PARAMATERS_UPDATE =
  "ADMIN_CATEGORY_SEARCH_PARAMATERS_UPDATE";
export const ADMIN_CATEGORY_LOADING_LIST_DATA =
  "ADMIN_CATEGORY_LOADING_LIST_DATA";
export const ADMIN_CATEGORY_DELETE = "ADMIN_CATEGORY_DELETE";
export const ADMIN_CATEGORY_UPDATED = "ADMIN_CATEGORY_UPDATED";
export const ADMIN_CATEGORY_GET_BY_ID = "ADMIN_CATEGORY_GET_BY_ID";
export const ADMIN_CATEGORY_CHANGE_STATUS = "ADMIN_CATEGORY_CHANGE_STATUS";
export const ADMIN_CATEGORY_LOADING_ON_SUBMIT =
  "ADMIN_CATEGORY_LOADING_ON_SUBMIT";
