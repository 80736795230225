import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { removeLabScripts } from "utils/sFold";
import { EMPLOYER_ROLE, ADMIN_ROLE, LAB_ROLE } from "constants/index";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !loading) {
          if (props.match.path.match(/lab/)) {
            removeLabScripts("lab_panel_css", "style");
            removeLabScripts("lab_panel_js", "script");
          }
          return <Redirect to="/" />;
        } else {
          if (user !== null) {
            var isAdmin = false;
            var isEmployer = false;
            var isLab = false;

            switch (user.role) {
              case ADMIN_ROLE:
                isAdmin = true;
                break;
              case EMPLOYER_ROLE:
                isEmployer = true;
                break;
              case LAB_ROLE:
                isLab = true;
                break;
            }

            if (isAdmin) {
              removeLabScripts("lab_panel_css", "style");
              removeLabScripts("lab_panel_js", "script");
              if (props.match.path.match(/admin/)) return <Component {...props} />;
              else return <Redirect to="/admin/dashboard" />;
            // } else if (isLab) {
            //   if (props.match.path.match(/lab/)) return <Component {...props} />;
            //   else return <Redirect to="/lab/profile" />;
            }else if (isEmployer) {
              if (props.match.path.match(/organization/)) return <Component {...props} />;
              else return <Redirect to="/organization/profile" />;
            }
            else return <Redirect to="/" />;
          } else return <Component {...props} />;
        }
      }}
    />
  );
};
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
