import {
  SUPER_ADMIN_CREATED,
  SUPER_ADMIN_ERROR,
  SUPER_ADMIN_DELETE,
  SUPER_ADMIN_UPDATED,
  SUPER_ADMIN_LIST_UPDATED,
  GET_SUPER_ADMIN_BY_ID,
  SUPER_ADMIN_SEARCH_PARAMATERS_UPDATE,
  CHANGE_SUPER_ADMIN_STATUS,
  LOADING_ON_SUPER_ADMIN_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_SUPER_ADMIN_LIST_DATA,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  superAdminList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentSuperAdmin: null,
  loadingSuperAdminList: true,
  loadingSuperAdmin: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingSuperAdmin: state.loadingSuperAdmin,
        loadingSuperAdminList: state.loadingSuperAdminList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingSuperAdmin: false,
      };
    case SUPER_ADMIN_CREATED:
      return {
        ...state,
        loadingSuperAdmin: false,
      };
    case SUPER_ADMIN_UPDATED:
      return {
        ...state,
        currentSuperAdmin: payload,
        sortingParams: initialState.sortingParams,
        loadingSuperAdmin: false,
      };
    case SUPER_ADMIN_DELETE:
      const currentCount = state.superAdminList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.superAdminList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        superAdminList: {
          data: state.superAdminList.data.filter(
            (staff) => staff._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingSuperAdminList: false,
        performDelete: true,
      };
    case SUPER_ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loadingSuperAdmin: false,
        loadingSuperAdminList: false,
        loadingListData: false,
        performDelete: false,
      };
    case GET_SUPER_ADMIN_BY_ID:
      return {
        ...state,
        currentSuperAdmin: payload,
        loadingSuperAdmin: false,
      };
    case SUPER_ADMIN_LIST_UPDATED:
      return {
        ...state,
        superAdminList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingSuperAdmin: true,
        loadingSuperAdminList: false,
        loadingListData: false,
        performDelete: false,
      };
    case SUPER_ADMIN_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_SUPER_ADMIN_STATUS:
      return {
        ...state,
        loadingListData: false,
        superAdminList: {
          ...state.superAdminList,
          data: state.superAdminList.data.map((staff) =>
            staff._id === payload._id
              ? { ...staff, status: payload.status }
              : staff
          ),
        },
      };
    case LOADING_SUPER_ADMIN_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_SUPER_ADMIN_SUBMIT:
      return {
        ...state,
        loadingSuperAdmin: true,
      };
    default:
      return state;
  }
}
