import {
  INQUIRY_CREATED,
  INQUIRY_ERROR,
  DELETE_INQUIRY,
  INQUIRY_UPDATED,
  INQUIRY_LIST_UPDATED,
  GET_INQUIRY_BY_ID,
  INQUIRY_SEARCH_PARAMATERS_UPDATE,
  CHANGE_INQUIRY_STATUS,
  LOADING_ON_INQUIRY_SUBMIT,
  LOAD_PAGE,
  LOADING_INQUIRY_LIST_DATA,
  RESET,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  inquiriesList: {
    page: 1,
    data: [],
    count: 0
  },
  currentInquiry: [],
  loadingInquiryList: true,
  loadingInquiry: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    // orderBy: 'created_at',
    orderBy: "created_at",
    ascending: "desc",
    query: ""
  }
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingInquiry: state.loadingInquiry,
        loadingInquiryList:state.loadingInquiryList,
        loadingListData: state.loadingListData,
      }
    case INQUIRY_CREATED:
      return {
        ...state,
        loadingInquiry: false
      };
    case LOAD_PAGE: 
      return {
        ...state,
        loadingInquiry: false
      };
    case INQUIRY_UPDATED:
      return {
        ...state,
        currentInquiry: payload,
        sortingParams: initialState.sortingParams,
        loadingInquiry: false
      };
    case INQUIRY_ERROR:
      return {
        ...state,
        error: payload,
        loadingInquiry: false,
        loadingInquiryList: false,
        loadingListData: false,
        performDelete: false,

      };
    case DELETE_INQUIRY:
      const currentCount = state.inquiriesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.inquiriesList.page);
      let remainingPages = Math.ceil((currentCount - 1)/currentLimit);
      remainingPages = remainingPages?remainingPages:1;
      return {
        ...state,
        inquiriesList: {
          data: state.inquiriesList.data.filter(
            inquiry => inquiry._id !== payload
          ),
          count: currentCount - 1,
          page: currentPage <= remainingPages?currentPage.toString(): remainingPages.toString(),
        },
        loadingInquiryList: false,
        performDelete: true,
      };
    case GET_INQUIRY_BY_ID:
      return {
        ...state,
        currentInquiry: payload,
        loadingInquiry: false
      };
    case INQUIRY_LIST_UPDATED:
      return {
        ...state,
        inquiriesList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord
        },
        // loadingInquiry: true,
        loadingInquiryList: false,
        loadingListData: false,
        performDelete: false,
      };
    case INQUIRY_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case CHANGE_INQUIRY_STATUS:
      return {
        ...state,
        inquiriesList: {
          ...state.inquiriesList,
          data: state.inquiriesList.data.map(inquiry =>
            inquiry._id === payload._id
              ? { ...inquiry, status: payload.status }
              : inquiry
          )
        }
      };
    case LOADING_INQUIRY_LIST_DATA: 
      return {
        ...state,
        loadingListData: true
      };
    case LOADING_ON_INQUIRY_SUBMIT: 
      return {
        ...state,
        loadingInquiry: true
      };

    default:
      return state;
  }
}
