import {
  IRB_ERROR,
  IRB_DELETE,
  IRB_LIST_UPDATED,
  IRB_SEARCH_PARAMATERS_UPDATE,
  GET_IRB_BY_ID,
  IRB_UPDATED,
  LOADING_IRB_LIST_DATA,
  LOADING_ON_IRB_SUBMIT,
  LOAD_PAGE,
  RESET,
  IRB_NAMES_LIST_DATA,
  IRB_NAMES_LIST_LOADING,
  IRB_CREATED,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  IRBList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentIRB: null,
  loadingIRBList: true,
  loadingIRB: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  loadingIRBNames: false,
  IRBNamesList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingIRB: state.loadingStaff,
        loadingIRBList: state.loadingStaffList,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingIRB: false,
      };
    case IRB_DELETE:
      const currentCount = state.staffList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.staffList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        staffList: {
          data: state.staffList.data.filter((staff) => staff._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingStaffList: false,
        performDelete: true,
      };
    case IRB_LIST_UPDATED:
      return {
        ...state,
        IRBList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingIRBList: false,
        loadingIRB: false,
      };
    case IRB_CREATED:
      return {
        ...state,
        loadingIRB: false,
      };
    case IRB_ERROR:
      return {
        ...state,
        error: payload,
        loadingIRB: false,
        loadingIRBList: false,
      };
    case IRB_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_IRB_LIST_DATA:
      return {
        ...state,
        loadingIRBList: true,
      };
    case LOADING_ON_IRB_SUBMIT:
      return {
        ...state,
        loadingIRB: true,
      };
    case IRB_NAMES_LIST_LOADING:
      return {
        ...state,
        loadingIRBNames: true,
      };
    case IRB_NAMES_LIST_DATA:
      return {
        ...state,
        IRBNamesList: payload,
      };
    case GET_IRB_BY_ID:
      return {
        ...state,
        currentIRB: payload,
        loadingIRB: false,
      };
    case IRB_UPDATED:
      return {
        ...state,
        currentIRB: payload,
        sortingParams: initialState.sortingParams,
        loadingIRB: false,
      };
    default:
      return state;
  }
}
