import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { login, forgotPassword, loadUser, loadPage } from "actions/auth";
import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import * as Constants from "constants/index";
import Errors from "views/Notifications/Errors";
import LogoSvg from "assets/iLabDx-Logo.png";
import { Img } from "react-image";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

import { ENABLE_RESEND_OTP_OPTION } from "./../../actions/types";

const Login = ({
  login,
  loadUser,
  loadPage,
  loading,
  errorList,
  isAuthenticated,
  otpSentForLogin,
  enableResendOTPOption,
  forgotPassword,
  history,
}) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    otp: "",
    userType: Constants.ADMIN_ROLE,
  });

  const { email, password, otp } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    setShowResendButton(false);

    const submitData = {
      email: formData.email,
      password: formData.password,
      askForTwoFactor: true,
      userType: 1,
    };

    login(submitData, history).then((res) => {
      console.log({ res });
      if (res.status !== false) {
        console.log("two factor asked.", "\n", "Resend OTP option enabled.");
        dispatch({
          type: ENABLE_RESEND_OTP_OPTION,
        });

        showResendOTPOptionWithTimer();
      }
    });
  };

  const requestResendOTP = () => {
    setShowResendButton(false);

    const submitData = {
      email: formData.email,
      password: formData.password,
      askForTwoFactor: true,
      userType: 1,
      requestResendOTP: true,
    };

    console.log("Request resend OTP. submitData", submitData);
    login(submitData, history).then((res) => {
      console.log("two factor asked.", "\n", "Resend OTP option enabled.");
      dispatch({
        type: ENABLE_RESEND_OTP_OPTION,
      });

      showResendOTPOptionWithTimer();
    });
  };

  useMemo(() => {
    loadPage();
  }, [loadPage]);

  useEffect(() => {
    let subscribe = true;
    const asyncLoadUser = async () => {
      if (subscribe && isAuthenticated) {
        loadUser();
      }
    };
    asyncLoadUser();
    return () => {
      subscribe = false;
    };
  }, [isAuthenticated, loadUser]);

  const [hidden, setHidden] = useState(true);
  const [icon, setIcon] = useState("icon-eye");
  const onClickPasswordIcon = (e) => {
    setHidden(!hidden);
    setIcon(icon === "icon-eye" ? "icon-lock-open" : "icon-eye");
  };

  const [loginOrTwoFactor, setloginOrTwoFactor] = useState("");
  useEffect(() => {
    // console.log("otpSentForLogin: ", otpSentForLogin);
    if (otpSentForLogin === false) {
      setloginOrTwoFactor("login");
    }

    if (otpSentForLogin === true) {
      setloginOrTwoFactor("twofactor");
    }
  }, [otpSentForLogin]);

  const onSubmitTwoFactor = (e) => {
    e.preventDefault();

    login(formData, history);
  };

  const [showResendButton, setShowResendButton] = useState(false);
  const [textToShowForResendTimer, setTextToShowForResendTimer] = useState("");
  const showResendOTPOptionWithTimer = () => {
    var timeLeft = 60;
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        setShowResendButton(true);
        setTextToShowForResendTimer("");
        clearInterval(downloadTimer);
      } else {
        setTextToShowForResendTimer("Resend OTP in " + timeLeft + " seconds");
      }
      timeLeft -= 1;
    }, 1000);
  };

  const toInputLowercase = (e) => {
    e.target.value = ("" + e.target.value).toLowerCase();
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="text-center w-100 d-block mb-4">
              <Img alt="" src={LogoSvg} className="w-70 admin-login-logo" />
            </div>
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {loginOrTwoFactor === "login" && (
                    <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                      <h1>Login</h1>
                      <p className="text-muted">
                        Sign In to administrator account <br></br>
                      </p>
                      <Alert />
                      <InputGroup className="mb-3">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                        <Input
                          type="text"
                          placeholder="Email"
                          name="email"
                          maxLength="50"
                          value={email}
                          onChange={(e) => onChange(e)}
                          required
                          onInput={toInputLowercase}
                          autoFocus={true}
                          invalid={errorList.email ? true : false}
                        />
                        <Errors current_key="email" key="email" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                        <Input
                          type={hidden ? "password" : "text"}
                          placeholder="Password"
                          name="password"
                          minLength="8"
                          value={password}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.password ? true : false}
                        />
                        <InputGroupText
                          onClick={(e) => onClickPasswordIcon(e)}
                          title="Click to show/hide password"
                        >
                          <i className={icon}></i>
                        </InputGroupText>
                        <Errors current_key="password" key="password" />
                      </InputGroup>
                      <Row>
                        <Col xs="4">
                          <Button color="primary" className="px-4">
                            Login
                          </Button>
                        </Col>
                        <Col xs="8" className="text-right">
                          <Button
                            color="link"
                            className="px-0"
                            onClick={(e) => forgotPassword(history)}
                          >
                            Forgot password?
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}

                  {loginOrTwoFactor === "twofactor" && (
                    <Form
                      onSubmit={(e) => onSubmitTwoFactor(e)}
                      autoComplete="off"
                    >
                      <h1>Two factor authentication</h1>
                      <Alert />

                      <Row>
                        <Col xs="12" sm="12" md="12" lg="6">
                          <InputGroup className="mb-4">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                            <Input
                              type="text"
                              placeholder="Enter OTP"
                              name="otp"
                              minLength="6"
                              maxLength="7"
                              value={otp}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.password ? true : false}
                            />

                            <Errors current_key="otp" key="otp" />
                          </InputGroup>
                        </Col>

                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="6"
                          className="text-center"
                        >
                          <Button color="primary" className="px-4">
                            Verify
                          </Button>
                        </Col>

                        {enableResendOTPOption && (
                          <Col xs="12" sm="6">
                            {showResendButton === true ? (
                              <Button
                                color="link"
                                className="px-0"
                                onClick={(e) => requestResendOTP()}
                              >
                                Resend OTP
                              </Button>
                            ) : (
                              <span color="link" className="px-0 btn">
                                {textToShowForResendTimer}
                              </span>
                            )}
                          </Col>
                        )}
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.proTypes = {
  errorList: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  otpSentForLogin: PropTypes.bool.isRequired,
  enableResendOTPOption: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  errorList: state.errors,
  otpSentForLogin: state.auth.otpSentForLogin,
  enableResendOTPOption: state.auth.enableResendOTPOption,
});

export default connect(mapStateToProps, {
  login,
  loadUser,
  loadPage,
  forgotPassword,
})(Login);
