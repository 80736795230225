import {
  GROUP_TEST_CREATED,
  GROUP_TEST_ERROR,
  GROUP_TEST_DELETE,
  GROUP_TEST_UPDATED,
  GROUP_TEST_LIST_UPDATED,
  GET_GROUP_TEST_BY_ID,
  GROUP_TEST_SEARCH_PARAMATERS_UPDATE,
  CHANGE_GROUP_TEST_STATUS,
  LOADING_ON_GROUP_TEST_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_GROUP_TEST_LIST_DATA,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  groupTestList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentGroupTest: null,
  loadingGroupTestList: true,
  loadingGroupTest: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingGroupTest: state.loadingGroupTest,
        loadingGroupTestList: state.loadingGroupTestList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingGroupTest: false,
      };
    case GROUP_TEST_CREATED:
      return {
        ...state,
        loadingGroupTest: false,
      };
    case GROUP_TEST_UPDATED:
      return {
        ...state,
        currentGroupTest: payload,
        sortingParams: initialState.sortingParams,
        loadingGroupTest: false,
      };
    case GROUP_TEST_DELETE:
      const currentCount = state.groupTestList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.groupTestList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        groupTestList: {
          data: state.groupTestList.data.filter(
            (groupTest) => groupTest._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingGroupTestList: false,
        performDelete: true,
      };
    case GROUP_TEST_ERROR:
      return {
        ...state,
        error: payload,
        loadingGroupTest: false,
        loadingGroupTestList: false,
        loadingListData: false,
        performDelete: false,
      };
    case GET_GROUP_TEST_BY_ID:
      return {
        ...state,
        currentGroupTest: payload,
        loadingGroupTest: false,
      };
    case GROUP_TEST_LIST_UPDATED:
      return {
        ...state,
        groupTestList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingGroupTest: true,
        loadingGroupTestList: false,
        loadingListData: false,
        performDelete: false,
      };
    case GROUP_TEST_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_GROUP_TEST_STATUS:
      return {
        ...state,
        groupTestList: {
          ...state.groupTestList,
          data: state.groupTestList.data.map((groupTest) =>
            groupTest._id === payload._id
              ? { ...groupTest, status: payload.status }
              : groupTest
          ),
        },
      };
    case LOADING_GROUP_TEST_LIST_DATA:
      return {
        ...state,
        loadingGroupTestList: true,
      };
    case LOADING_ON_GROUP_TEST_SUBMIT:
      return {
        ...state,
        loadingGroupTest: true,
      };
    default:
      return state;
  }
}
