import {
  RESET,
  LOAD_PAGE,
  POSSIBLE_RESULT_CREATED,
  POSSIBLE_RESULT_ERROR,
  POSSIBLE_RESULT_DELETE,
  POSSIBLE_RESULT_UPDATED,
  POSSIBLE_RESULT_LIST_UPDATE,
  GET_POSSIBLE_RESULT_BY_ID,
  POSSIBLE_RESULTS_SEARCH_PARAMATERS_UPDATE,
  LOADING_ON_POSSIBLE_RESULT_SUBMIT,
  LOADING_POSSIBLE_RESULTS_LIST_DATA,
  UPDATE_POSSIBLE_RESULTS_LIST,
  UPDATE_CURRENT_TEST,
} from "actions/types";

const initialState = {
  possibleResultsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentTest: {},
  currentPossibleResult: null,
  loadingPossibleResultsList: true,
  loadingPossibleResult: true,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: 100,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingPossibleResult: state.loadingPossibleResult,
        loadingPossibleResultsList: state.loadingPossibleResultsList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingPossibleResult: false,
      };
    case POSSIBLE_RESULT_CREATED:
      return {
        ...state,
        loadingPossibleResult: false,
      };
    case POSSIBLE_RESULT_UPDATED:
      return {
        ...state,
        sortingParams: initialState.sortingParams,
        loadingPossibleResult: false,
      };
    case POSSIBLE_RESULT_DELETE:
      let currentCount = state.possibleResultsList.count;
      let currentLimit = initialState.sortingParams.limit;
      let currentPage = parseInt(state.possibleResultsList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        possibleResultsList: {
          data: state.possibleResultsList.data.filter(
            (test) => test._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingPossibleResult: false,
      };
    case POSSIBLE_RESULT_ERROR:
      return {
        ...state,
        error: payload,
        loadingPossibleResultsList: false,
        loadingPossibleResult: false,
        loadingListData: false,
      };
    case GET_POSSIBLE_RESULT_BY_ID:
      return {
        ...state,
        currentPossibleResult: payload,
        loadingPossibleResult: false,
      };
    case POSSIBLE_RESULT_LIST_UPDATE:
      return {
        ...state,
        possibleResultsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingPossibleResult: false,
        loadingPossibleResultsList: false,
        loadingListData: false,
      };
    case POSSIBLE_RESULTS_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case LOADING_POSSIBLE_RESULTS_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_POSSIBLE_RESULT_SUBMIT:
      return {
        ...state,
        loadingPossibleResult: true,
      };
    case UPDATE_POSSIBLE_RESULTS_LIST:
      return {
        ...state,
        possibleResultsList: payload,
      };
    case UPDATE_CURRENT_TEST:
      return {
        ...state,
        currentTest: payload,
      };
    default:
      return state;
  }
}
