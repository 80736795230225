import { ADMIN_DASHBOARD_GET_DATA_COUNT } from "actions/types";

const initialState = {
  dashboardDataCounts: {
    currentPatientsCount: 0,
    lastPatientsCount: 0,
    patientsPercent: 0,
    currentReportsCount: 0,
    lastReportsCount: 0,
    reportsPercent: 0,
    currentOrdersCount: 0,
    lastOrdersCount: 0,
    ordersPercent: 0,
    currentNoShowsOrdersCount: 0,
    lastNoShowsOrdersCount: 0,
    noShowsOrdersPercent: 0,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_DASHBOARD_GET_DATA_COUNT:
      return {
        ...initialState,
        dashboardDataCounts: payload,
      };

    default:
      return state;
  }
}
