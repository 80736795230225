import React from "react";
import { Switch, Route } from "react-router-dom";

import HomePage from "views/Front";
import Login from "../Auth/Login";
// import Register from "../Auth/Register";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "views/Auth/ForgotPassword/ResetPassword";
import VerifyEmail from "views/Auth/VerifyEmail";
import NotFoundPage from "views/NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import DynamicImport from "./DynamicImport";
import PatientRegistration from "views/Auth/Employee/PatientRegistraion";

const AdminPanel = (props) => (
  <DynamicImport load={() => import("views/Admin/Layout")}>
    {(Component) => (Component === null ? "" : <Component {...props} />)}
  </DynamicImport>
);

const Routes = () => {
  return (
    <span>
      <Switch>
        {/* Admin routes Start
        <PublicRoute
          exact
          path="/register"
          restricted={true}
          component={Register}
        />*/}
        <PublicRoute exact path="/login" restricted={true} component={Login} />
        <PublicRoute
          exact
          path="/forgotPassword"
          restricted={true}
          component={ForgotPassword}
        />
        <PublicRoute
          exact
          path="/resetPassword/:token"
          restricted={true}
          component={ResetPassword}
        />
        <PublicRoute
          exact
          path="/verify/email/:token"
          restricted={true}
          component={VerifyEmail}
        />

        <PublicRoute
          exact
          path="/patient-registration-form/:organizationID"
          restricted={false}
          component={PatientRegistration}
          childWindow={true}
        />

        <PrivateRoute path="/admin*" component={AdminPanel} />
        {/* Admin routes End  */}

        <Route exact path="/*" name="Home" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </span>
  );
};

export default Routes;
