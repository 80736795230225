import {
  EMPLOYER_CREATED,
  EMPLOYER_ERROR,
  EMPLOYER_DELETE,
  EMPLOYER_UPDATED,
  EMPLOYER_LIST_UPDATED,
  GET_EMPLOYER_BY_ID,
  EMPLOYER_SEARCH_PARAMATERS_UPDATE,
  CHANGE_EMPLOYER_STATUS,
  LOADING_ON_EMPLOYER_SUBMIT,
  RESET,
  LOAD_PAGE,
  LOADING_EMPLOYER_LIST_DATA,
  EMPLOYER_EMPLOYEES_LIST_UPDATED,
  LOADING_COMPANYADMIN_LIST,
  ADMIN_COMPANYADMIN_LIST,
  EMPLOYER_ARCHIVED,
  LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA,
  EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE,
  EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  employersList: {
    page: 1,
    data: [],
    count: 0,
  },
  employeesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEmployerAdmin: null,
  currentEmployer: null,
  loadingEmployerList: true,
  loadingEmployer: true,
  loadingListData: true,
  loadingCompanyAdmins: false,
  loadingEmployerAdmin: false,
  performDelete: false,
  companyAdmins: [],
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  loadingGlobalPatientListData: true,
  sortingGlobalPatientParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  globalPatientsList: {
    page: 1,
    data: [],
    count: 0,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingEmployer: state.loadingEmployer,
        loadingEmployerList: state.loadingEmployerList,
        loadingListData: state.loadingListData,
        loadingCompanyAdmins: state.loadingCompanyAdmins,
        loadingEmployerAdmin: state.loadingEmployerAdmin,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingEmployer: false,
      };
    case EMPLOYER_CREATED:
      return {
        ...state,
        loadingEmployer: false,
      };
    case LOADING_COMPANYADMIN_LIST:
      return {
        ...state,
        loadingCompanyAdmins: true,
      };
    case ADMIN_COMPANYADMIN_LIST:
      return {
        ...state,
        companyAdmins: payload,
        loadingCompanyAdmins: false,
      };
    case EMPLOYER_UPDATED:
      return {
        ...state,
        currentEmployer: payload,
        sortingParams: initialState.sortingParams,
        loadingEmployer: false,
      };
    case EMPLOYER_DELETE:
      const currentCount = state.employersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.employersList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        employersList: {
          data: state.employersList.data.filter(
            (employer) => employer._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingEmployerList: false,
        performDelete: true,
      };
    case EMPLOYER_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployer: false,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
        loadingCompanyAdmins: false,
        loadingEmployerAdmin: false,
      };
    case GET_EMPLOYER_BY_ID:
      return {
        ...state,
        currentEmployer: payload,
        loadingEmployer: false,
      };
    case EMPLOYER_LIST_UPDATED:
      return {
        ...state,
        employersList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingEmployer: true,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
      };
    case EMPLOYER_EMPLOYEES_LIST_UPDATED:
      return {
        ...state,
        employeesList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingEmployer: true,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
      };
    case EMPLOYER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_EMPLOYER_STATUS:
      return {
        ...state,
        employersList: {
          ...state.employersList,
          data: state.employersList.data.map((employer) =>
            employer._id === payload._id
              ? { ...employer, status: payload.status }
              : employer
          ),
        },
      };
    case LOADING_EMPLOYER_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_EMPLOYER_SUBMIT:
      return {
        ...state,
        loadingEmployer: true,
      };
    case EMPLOYER_ARCHIVED:
      const currentCount1 = state.employersList.count;
      const currentLimit1 = state.sortingParams.limit;
      const currentPage1 = parseInt(state.employersList.page);
      let remainingPages1 = Math.ceil((currentCount1 - 1) / currentLimit1);
      remainingPages1 = remainingPages1 ? remainingPages1 : 1;
      return {
        ...state,
        employersList: {
          data: state.employersList.data.filter(
            (employer) => employer._id !== payload
          ),
          count: currentCount1 - 1,
          page:
            currentPage1 <= remainingPages1
              ? currentPage1.toString()
              : remainingPages1.toString(),
        },
        loadingEmployerList: false,
        performDelete: true,
      };
    case LOADING_EMPLOYER_GLOBAL_PATIENT_LIST_DATA:
      return {
        ...state,
        loadingGlobalPatientListData: true,
      };
    case EMPLOYER_GLOBAL_PATIENT_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingGlobalPatientParams: { ...payload },
      };
    case EMPLOYER_GLOBAL_PATIENT_LIST_UPDATED:
      return {
        ...state,
        globalPatientsList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingGlobalPatientListData: false,
      };
    default:
      return state;
  }
}
