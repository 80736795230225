import {
  MERGE_PATIENTS_RESET,
  MERGE_PATIENTS_LOAD_PAGE,
  MERGE_PATIENTS_DETAILS,
  MERGE_PATIENTS_EMPLOYER_ERROR,
  MERGE_PATIENTS_LOADING_EMPLOYER_LIST,
  MERGE_PATIENTS_EMPLOYER_LIST_UPDATED,
  MERGE_PATIENTS_LOADING_ON_EMPLOYER_SUBMIT,
  MERGE_PATIENTS_EMPLOYER_EMPLOYEES_LIST_UPDATED,
  } from "actions/types";
  import * as Constants from "constants/index";

  const initialState = {
    mergeDetails: {},
    employersList: [],
    employeesList: [],
    loadingEmployerList: false,
    loadingEmployer: false,
    loadingListData: false,
    loadingOnSubmit: false,
    error: {},
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case MERGE_PATIENTS_RESET:
        return {
          ...initialState,
        }
      case MERGE_PATIENTS_LOAD_PAGE:
        return {
          ...state,
          loadingEmployer: false,
          loadingEmployerList: false,
          loadingListData: false,
          loadingOnSubmit: false,
        };
      case MERGE_PATIENTS_EMPLOYER_ERROR:
        return {
          ...state,
          error: payload,
          loadingEmployer: false,
          loadingEmployerList: false,
          loadingListData: false,
          loadingOnSubmit: false,
        };
      case MERGE_PATIENTS_EMPLOYER_LIST_UPDATED:
        return {
          ...state,
          employersList: payload,
          loadingEmployer: false,
          loadingEmployerList: false,
          loadingListData: false,
          loadingOnSubmit: false,
        };
      case MERGE_PATIENTS_EMPLOYER_EMPLOYEES_LIST_UPDATED:
        return {
          ...state,
          employeesList: payload,
          loadingEmployer: false,
          loadingEmployerList: false,
          loadingListData: false,
          loadingOnSubmit: false,
        };
      case MERGE_PATIENTS_DETAILS:
        return {
          ...state,
          mergeDetails: payload,
          loadingEmployer: false,
          loadingEmployerList: false,
          loadingListData: false,
          loadingOnSubmit: false,
        };
      case MERGE_PATIENTS_LOADING_EMPLOYER_LIST:
        return {
          ...state,
          loadingListData: true
        };
      case MERGE_PATIENTS_LOADING_ON_EMPLOYER_SUBMIT:
        return {
          ...state,
          loadingEmployer: true
        };
      default:
        return state;
    }
  }
