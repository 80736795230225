import {
  EMAIL_TEMPLATE_CREATED,
  EMAIL_TEMPLATE_ERROR,
  DELETE_EMAIL_TEMPLATE,
  EMAIL_TEMPLATE_UPDATED,
  EMAIL_TEMPLATE_LIST_UPDATED,
  GET_EMAIL_TEMPLATE_BY_ID,
  LOADING_ON_EMAIL_SUBMIT,
  EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE,
  CHANGE_EMAIL_TEMPLATE_STATUS,
  LOAD_PAGE,
  LOADING_EMAIL_LIST_DATA,
  RESET,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  emailTemplateList: {
    page: 1,
    data: [],
    count: 0
  },
  currentEmailTemplate: [],
  loadingEmailList: true,
  loadingEmail: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: ""
  }
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingEmail: state.loadingEmail,
        loadingEmailList:state.loadingEmailList,
        loadingListData: state.loadingListData,
      }
    case LOAD_PAGE:
      return {
        ...state,
        loadingEmail: false
      };
    case EMAIL_TEMPLATE_CREATED:
      return {
        ...state,
        loadingEmail: false
      };
    case EMAIL_TEMPLATE_UPDATED:
      return {
        ...state,
        currentEmailTemplate: payload,
        sortingParams: initialState.sortingParams,
        loadingEmail: false
      };
    case EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmail: false,
        loadingEmailList: false,
        loadingListData: false,
        performDelete: false,
      };
    case DELETE_EMAIL_TEMPLATE:
      const currentCount = state.emailTemplateList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.emailTemplateList.page);
      let remainingPages = Math.ceil((currentCount - 1)/currentLimit);
      remainingPages = remainingPages?remainingPages:1;
      return {
        ...state,
        emailTemplateList: {
          data: state.emailTemplateList.data.filter(
            emailTemplate => emailTemplate._id !== payload
          ),
          count: currentCount - 1,
          page: currentPage <= remainingPages?currentPage.toString(): remainingPages.toString(),
        },
        loadingEmailList: false,
        performDelete: true,
      };
    case GET_EMAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        currentEmailTemplate: payload,
        loadingEmail: false
      };
    case EMAIL_TEMPLATE_LIST_UPDATED:
      return {
        ...state,
        emailTemplateList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord
        },
        // loadingEmail: true,
        loadingEmailList: false,
        loadingListData: false,
        performDelete: false,
      };
    case EMAIL_TEMPLATE_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };

    case CHANGE_EMAIL_TEMPLATE_STATUS:
      return {
        ...state,
        emailTemplateList: {
          ...state.emailTemplateList,
          data: state.emailTemplateList.data.map(emailTemplate =>
            emailTemplate._id === payload._id
              ? { ...emailTemplate, status: payload.status }
              : emailTemplate
          )
        }
      };
    case LOADING_EMAIL_LIST_DATA:
      return {
        ...state,
        loadingListData: true
      };
    case LOADING_ON_EMAIL_SUBMIT:
      return {
        ...state,
        loadingEmail: true
      };

    default:
      return state;
  }
}
