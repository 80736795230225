import { useEffect, useState } from 'react';

const DynamicImport = (props) => {
    const [component, setComponent] = useState(null);
    useEffect(() => {
        let subscribe = true;
        const asyncCall = async () => {
            try {
                if (subscribe) {
                    props.load().then((component) => {
                        subscribe && setComponent(() => component.default ? component.default : component);
                    }).catch((err) => console.log(err));
                }
            }
            catch (err) {
                // throw err;
            }
        }
        asyncCall();
        return () => {
            subscribe = false;
        }
    }, []);

    return props.children(component);
}

export default DynamicImport;