import {
    LAB_ADMIN_ERROR,
    LAB_ADMIN_CREATED,
    LAB_ADMIN_UPDATED,
    LAB_ADMIN_DELETE,
    CHANGE_LAB_ADMIN_STATUS,
    GET_LAB_ADMIN_BY_ID,
    LOADING_ON_LAB_ADMIN_SUBMIT,
    LOADING_LAB_ADMIN_LIST_DATA,
    LAB_ADMIN_SEARCH_PARAMATERS_UPDATE,
    LAB_ADMIN_LIST_UPDATED,
    LAB_LIST_BY_COMPANY_ADMIN_UPDATED,
    RESET,
    LOAD_PAGE,
    LAB_IDS_LIST_UPDATED,
  } from "actions/types";
  import * as Constants from "constants/index";

  const initialState = {
    currentLabAdmin: null,
    performDelete: false,
    loadingLabAdmin: false,
    loadingListData: false,
    loadingLabAdminList: false,
    labIdList: [],
    labListByCompanyAdmin: [],
    error: {},
    labAdminList: {
      page: 1,
      data: [],
      count: 0
    },
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
      filters:[],
    },
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return {
          ...initialState,
          loadingLabAdmin: state.loadingLabAdmin,
          loadingListData: state.loadingListData,
          loadingLabAdminList: state.loadingLabAdminList,
          performDelete: false,
        }
      case LOAD_PAGE:
        return {
          ...state,
          loadingLabAdmin: false
        };
      case LAB_ADMIN_CREATED:
        return {
          ...state,
          loadingLabAdmin: false
        };
      case LAB_ADMIN_UPDATED:
        return {
          ...state,
          currentLabAdmin: payload,
          sortingParams: initialState.sortingParams,
          loadingLabAdmin: false,
        };
      case LAB_ADMIN_ERROR:
        return {
          ...state,
          error: payload,
          performDelete: false,
          loadingLabAdmin: false,
          loadingLabAdminList: false,
          loadingListData: false,
        };
      case LAB_ADMIN_DELETE:
        const currentCount = state.labAdminList.count;
        const currentLimit = state.sortingParams.limit;
        const currentPage = parseInt(state.labAdminList.page);
        let remainingPages = Math.ceil((currentCount - 1)/currentLimit);
        remainingPages = remainingPages?remainingPages:1;
        return {
          ...state,
          labAdminList: {
            data: state.labAdminList.data.filter(admin =>
              admin._id !== payload
            ),
            count: currentCount - 1,
            page: currentPage <= remainingPages?currentPage.toString(): remainingPages.toString(),
          },
          loadingLabAdminList: false,
          performDelete: true,
        };
      case CHANGE_LAB_ADMIN_STATUS:
        return {
          ...state,
          labAdminList: {
            ...state.labAdminList,
            data: state.labAdminList.data.map(admin =>
              admin._id === payload._id
                ? { ...admin, status: payload.status }
                : admin
            )
          }
        };
      case GET_LAB_ADMIN_BY_ID:
        return {
          ...state,
          currentLabAdmin: payload,
          loadingLabAdmin: false,
        };
      case LAB_ADMIN_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload }
        };
      case LAB_LIST_BY_COMPANY_ADMIN_UPDATED:
        return {
          ...state,
          labListByCompanyAdmin: payload,
          loadingLabAdminList: false,
          loadingListData: false,
          performDelete: false,
        };
      case LAB_IDS_LIST_UPDATED:
        return {
          ...state,
          labIdList: payload,
          loadingLabAdminList: false,
          loadingListData: false,
          performDelete: false,
        };
      case LAB_ADMIN_LIST_UPDATED:
        return {
          ...state,
          labAdminList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord
          },
          loadingLabAdminList: false,
          loadingListData: false,
        };
      case LOADING_ON_LAB_ADMIN_SUBMIT:
        return {
          ...state,
          loadingLabAdmin: true
        }
      case LOADING_LAB_ADMIN_LIST_DATA:
        return {
          ...state,
          loadingListData: true
        };
      default:
        return state;
    }
  }
