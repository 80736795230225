import { combineReducers } from "redux";
import adminStaff from "./admin/adminStaff";
import superAdmin from "./admin/superAdmin";
import physician from "./admin/physician";

import logs from "./admin/logs";

import alert from "./alert";
import auth from "./auth";
import cms from "./admin/cms";
import emailTemplate from "./admin/emailTemplate";
import errors from "./errors";
import inquiry from "./admin/inquiry";
import common from "./commonReducer";

import profile from "./admin/profile";
import setting from "./admin/setting";

import employerAdmin from "./admin/employerAdmin";
import employer from "./admin/employer";
import patient from "./admin/patient";
import lab from "./admin/lab";
import labAdmin from "./admin/labAdmin";
import testOrder from "./admin/testOrderReducer";
import product from "./admin/productReducer";
import groups from "./admin/groups";
import recurringOrder from "./admin/recurringOrder";
import smsTemplate from "./admin/smsTemplate";
import mergePatients from "./admin/mergePatients";
import activityLogs from "./admin/activityLogs";
import parentCompany from "./admin/parentCompanyReducer";
import possibleResults from "./admin/possibleResults";
import testConfig from "./admin/testConfigReducer";
import drugTest from "./admin/drugTest";
import adminDashboard from "./admin/adminDashboard";
import UserProfile from "./admin/UserProfile";
import practice from "./admin/practice";
import productCategory from "./admin/productCategoryReducer";
import IRB from "./admin/IRBReducer";

const appReducer = combineReducers({
  //for admin
  superAdmin,
  adminStaff,
  adminStaff,
  physician,
  logs,
  alert,
  auth,
  cms,
  emailTemplate,
  errors,
  inquiry,
  profile,
  setting,
  employer,
  employerAdmin,
  lab,
  labAdmin,
  testOrder,
  product,
  groups,
  possibleResults,
  recurringOrder,
  smsTemplate,
  patient,
  mergePatients,
  activityLogs,
  parentCompany,
  testConfig,
  drugTest,
  adminDashboard,
  UserProfile,
  common,
  practice,
  productCategory,
  IRB,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
