import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Dashboard = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return (
      <div className="landing-inner">
        <h1 className="x-large">{process.env.REACT_APP_APP_NAME}</h1>
        <p className="lead">Welcome to our website.</p>{" "}
      </div>
    );
  }

  return (
    <div className="landing-inner">
      <h1 className="x-large">{process.env.REACT_APP_APP_NAME}</h1>
      <div className="buttons">
        <Link to="/login" className="btn btn-light">
          Admin Login
        </Link>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Dashboard);
