import {
  ADMIN_TEST_ORDER_CREATED,
  ADMIN_TEST_ORDER_ERROR,
  ADMIN_TEST_ORDER_DELETE,
  ADMIN_TEST_ORDER_UPDATED,
  ADMIN_TEST_ORDER_LIST_UPDATED,
  ADMIN_TEST_ORDER_GET_BY_ID,
  ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID,
  ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE,
  ADMIN_TEST_ORDER_CHANGE_STATUS,
  ADMIN_TEST_ORDER_LOADING_ON_SUBMIT,
  RESET,
  LOAD_PAGE,
  ADMIN_TEST_ORDER_LOADING_LIST_DATA,
  ADMIN_TEST_ORDER_EMPLOYERS_LIST,
  ADMIN_TEST_ORDER_EMPLOYEES_LIST,
  ADMIN_TEST_ORDER_STAFF_LIST,
  ADMIN_TEST_ORDER_PHYSICIAN_LIST,
  ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST,
  ADMIN_TEST_ORDER_NURSE_LIST,
  ADMIN_TEST_ORDER_LAB_LIST,
  ADMIN_TEST_ORDER_CANCEL_SUBMIT,
  ADMIN_TEST_ORDER_CANCEL_COMPLETE,
  ADMIN_TEST_ORDER_NOTE_ERROR,
  ADMIN_TEST_ORDER_NOTE_CREATED,
  ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID,
  ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT,
  ADMIN_TEST_ORDER_PATIENT_DATA,
  ADMIN_TEST_ORDER_BARCODE_ADDED,
  ADMIN_TEST_ORDER_ARCHIVED,
  ADMIN_TEST_ORDER_VALIDATE_PATIENTS,
  ADMIN_TEST_ORDER_BARCODING_STATUS,
  ADMIN_TEST_ORDER_EMPLOYER_LOCATIONS,
  ADMIN_TEST_ORDER_STATES_LIST,
  ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC,
  ADMIN_TEST_ORDER_CLIENT_LIST,
  EMPLOYER_BUSINESS_TYPES_UPDATED,
  ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO,
  ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  testOrdersList: {
    page: 1,
    data: [],
    count: 0,
  },
  employersList: [],
  employeesList: [],
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  staffList: [],
  physicianList: [],
  phlebotomistList: [],
  nurseList: [],
  labList: [],
  loadingCancelTestOrder: false,
  validatedCSVs: [],
  barcoding: false,
  locationsList: [],
  statesList: [],
  clientList: [],
  employerBusinessTypes: [],
  orgShortInfo: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
      };
    case ADMIN_TEST_ORDER_CREATED:
      return {
        ...state,
        loadingTestOrder: false,
      };
    case ADMIN_TEST_ORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: payload,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case ADMIN_TEST_ORDER_DELETE:
      const currentCount = state.testOrdersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.testOrdersList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        testOrdersList: {
          data: state.testOrdersList.data.filter(
            (testOrder) => testOrder._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingTestOrderList: false,
        performDelete: true,
      };
    case ADMIN_TEST_ORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_TEST_ORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case ADMIN_TEST_ORDER_RESULT_DELETE_BY_ID:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          order_items: state.currentTestOrder.order_items.map((eachItem) =>
            eachItem._id === payload.order_item_id
              ? {
                  ...eachItem,
                  results: eachItem.results.filter(
                    (each) => each._id !== payload.result
                  ),
                }
              : eachItem
          ),
        },
      };
    case ADMIN_TEST_ORDER_LIST_UPDATED:
      return {
        ...state,
        testOrdersList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingTestOrder: true,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_TEST_ORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case ADMIN_TEST_ORDER_CHANGE_STATUS:
      return {
        ...state,
        loadingListData: false,
        testOrdersList: {
          ...state.testOrdersList,
          data: state.testOrdersList.data.map((testOrder) =>
            testOrder._id === payload._id
              ? { ...testOrder, status: payload.status }
              : testOrder
          ),
        },
      };
    case ADMIN_TEST_ORDER_LOADING_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case ADMIN_TEST_ORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
      };
    case ADMIN_TEST_ORDER_EMPLOYERS_LIST:
      return {
        ...state,
        employersList: payload,
      };
    case ADMIN_TEST_ORDER_EMPLOYEES_LIST:
      return {
        ...state,
        employeesList: payload,
      };
    case ADMIN_TEST_ORDER_STAFF_LIST:
      return {
        ...state,
        staffList: payload,
      };
    case ADMIN_TEST_ORDER_PHYSICIAN_LIST:
      return {
        ...state,
        physicianList: payload,
      };
    case ADMIN_TEST_ORDER_PHLEBOTOMIST_LIST:
      return {
        ...state,
        phlebotomistList: payload,
      };
    case ADMIN_TEST_ORDER_NURSE_LIST:
      return {
        ...state,
        nurseList: payload,
      };
    case ADMIN_TEST_ORDER_LAB_LIST:
      return {
        ...state,
        labList: payload,
      };
    case ADMIN_TEST_ORDER_CANCEL_SUBMIT:
      return {
        ...state,
        loadingCancelTestOrder: true,
      };
    case ADMIN_TEST_ORDER_CANCEL_COMPLETE:
      return {
        ...state,
        loadingCancelTestOrder: false,
      };
    case ADMIN_TEST_ORDER_NOTE_CREATED:
      return {
        ...state,
        currentNote: payload,
        loading: false,
      };
    case ADMIN_TEST_ORDER_NOTE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADMIN_TEST_ORDER_LOADING_ON_NOTE_SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_TEST_ORDER_GET_NOTES_BY_ORDER_ID:
      return {
        ...state,
        notes: payload,
        loading: false,
      };
    case ADMIN_TEST_ORDER_PATIENT_DATA:
      return {
        ...state,
        orderPatientDataForCSV: payload,
      };
    case ADMIN_TEST_ORDER_BARCODE_ADDED:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          order_items: state.currentTestOrder.order_items.map((eachItem) =>
            eachItem._id === payload.orderItemID
              ? { ...eachItem, sampleID: [payload.barcode] }
              : eachItem
          ),
        },
      };
    case ADMIN_TEST_ORDER_ARCHIVED:
      const currentCount1 = state.testOrdersList.count;
      const currentLimit1 = state.sortingParams.limit;
      const currentPage1 = parseInt(state.testOrdersList.page);
      let remainingPages1 = Math.ceil((currentCount1 - 1) / currentLimit1);
      remainingPages1 = remainingPages1 ? remainingPages1 : 1;
      return {
        ...state,
        testOrdersList: {
          data: state.testOrdersList.data.filter(
            (testOrder) => testOrder._id !== payload
          ),
          count: currentCount1 - 1,
          page:
            currentPage1 <= remainingPages1
              ? currentPage1.toString()
              : remainingPages1.toString(),
        },
        loadingTestOrderList: false,
        performDelete: true,
      };
    case ADMIN_TEST_ORDER_VALIDATE_PATIENTS:
      return {
        ...state,
        validatedCSVs: payload,
      };

    case ADMIN_TEST_ORDER_BARCODING_STATUS:
      return {
        ...state,
        barcoding: payload,
      };
    case ADMIN_TEST_ORDER_EMPLOYER_LOCATIONS:
      return {
        ...state,
        locationsList: payload,
      };
    case ADMIN_TEST_ORDER_STATES_LIST:
      return {
        ...state,
        statesList: payload,
      };
    case ADMIN_TEST_ORDER_CHANGE_STATUS_SPECIFIC:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          status: payload,
        },
      };
    case ADMIN_TEST_ORDER_CLIENT_LIST:
      return {
        ...state,
        clientList: payload,
      };
    case EMPLOYER_BUSINESS_TYPES_UPDATED:
      return {
        ...state,
        employerBusinessTypes: payload,
      };
    case ADMIN_TEST_ORDER_EMPLOYEE_EMPLOYER_INFO:
      return {
        ...state,
        orgShortInfo: payload,
      };
    case ADMIN_TEST_ORDER_ORDER_ITEM_REMOVED:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          order_items: state.currentTestOrder.order_items.filter(
            (eachItem) => eachItem._id !== payload
          ),
        },
      };
    default:
      return state;
  }
}
