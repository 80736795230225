import moment from "moment";

export default (dob) => {
  try {
    if (!dob) return "NA";
    var date_today = moment();
    var age = date_today.diff(dob, 'years'); // Patient's age in years
    return age
  } catch (err) {
    return "NA";
  }
}
