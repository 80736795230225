import {
  ADMIN_TEST_ORDER_ACTIVITY_LOGS_LOADING,
  ADMIN_TEST_ORDER_ACTIVITY_LOGS,
} from "actions/types";

const initialState = {
  logsList: [],
  loading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_TEST_ORDER_ACTIVITY_LOGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_TEST_ORDER_ACTIVITY_LOGS:
      return {
        ...state,
        logsList: payload,
        loading: false,
      };
    default:
      return state;
  }
}
