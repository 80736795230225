import {
  ADMIN_CATEGORY_CREATED,
  ADMIN_CATEGORY_ERROR,
  ADMIN_CATEGORY_DELETE,
  ADMIN_CATEGORY_UPDATED,
  ADMIN_CATEGORY_LIST_UPDATED,
  ADMIN_CATEGORY_GET_BY_ID,
  ADMIN_CATEGORY_SEARCH_PARAMATERS_UPDATE,
  ADMIN_CATEGORY_CHANGE_STATUS,
  ADMIN_CATEGORY_LOADING_ON_SUBMIT,
  RESET,
  LOAD_PAGE,
  ADMIN_CATEGORY_LOADING_LIST_DATA,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  List: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCategory: null,
  loadingList: true,
  loadingRecord: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingRecord: state.loadingRecord,
        loadingList: state.loadingList,
        loadingListData: state.loadingListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingRecord: false,
      };
    case ADMIN_CATEGORY_CREATED:
      return {
        ...state,
        loadingRecord: false,
      };
    case ADMIN_CATEGORY_UPDATED:
      return {
        ...state,
        currentCategory: payload,
        sortingParams: initialState.sortingParams,
        loadingRecord: false,
      };
    case ADMIN_CATEGORY_DELETE:
      const currentCount = state.List.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.List.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        List: {
          data: state.List.data.filter((practice) => practice._id !== payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingList: false,
        performDelete: true,
      };
    case ADMIN_CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
        loadingRecord: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_CATEGORY_GET_BY_ID:
      return {
        ...state,
        currentCategory: payload,
        loadingRecord: false,
      };

    case ADMIN_CATEGORY_LIST_UPDATED:
      return {
        ...state,
        List: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingRecord: true,
        loadingList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_CATEGORY_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case ADMIN_CATEGORY_CHANGE_STATUS:
      return {
        ...state,
        loadingListData: false,
        List: {
          ...state.List,
          data: state.List.data.map((practice) =>
            practice._id === payload._id
              ? { ...practice, status: payload.status }
              : practice
          ),
        },
      };
    case ADMIN_CATEGORY_LOADING_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case ADMIN_CATEGORY_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingRecord: true,
      };
    default:
      return state;
  }
}
