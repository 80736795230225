import axios from "axios";
import { setAlert, removeAlert } from "./alert";
import { setErrorsList } from "./errors";
import {
  VERIFICATION_LINK_SUCCESS,
  VERIFICATION_LINK_FAIL,
  LOADING_ON_EMAIL_VERIFICATION,
  REMOVE_ERRORS,
  REMOVE_ALERT,
} from "./types";


// Verify Email
export const verifyEmail = (token) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnEmailVerification());
    const res = await axios.post(`/api/verify/email/${token}`, config);
    if (res.data.status === true) {
      dispatch({
        type: VERIFICATION_LINK_SUCCESS,
        payload: res.data.response
      });
    } else {
      // dispatch(tokenInvalid());
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data?res.data:{status:false};
  } catch (err) {
    if(err.response){
      dispatch({
        type: VERIFICATION_LINK_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
    }
  }
};


// Redirect to Login screen
export const loginRedirect = history => async dispatch => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/login");
};


// Dispatch Loading
export const loadingOnEmailVerification = () => async dispatch => {
  await dispatch({ type: LOADING_ON_EMAIL_VERIFICATION });
};

