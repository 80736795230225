import {
  PATIENT_ERROR,
  LOADING_PATIENT_BY_ID,
  GET_PATIENT_BY_ID,
  LOAD_PAGE,
} from "actions/types";

const initialState = {
  currentPatient: null,
  loadingPatient: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PAGE:
      return {
        ...state,
        loadingEmployer: false,
      };
    case PATIENT_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployer: false,
        loadingEmployerList: false,
        loadingListData: false,
        performDelete: false,
        loadingCompanyAdmins: false,
        loadingPatient: false,
      };
    case LOADING_PATIENT_BY_ID:
      return {
        ...state,
        loadingPatient: true,
      };
    case GET_PATIENT_BY_ID:
      return {
        ...state,
        currentPatient: payload,
        loadingPatient: false,
      };
    default:
      return state;
  }
}
