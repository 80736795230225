import {
    EMPLOYER_ADMIN_ERROR,
    EMPLOYER_ADMIN_CREATED,
    EMPLOYER_ADMIN_UPDATED,
    EMPLOYER_ADMIN_DELETE,
    CHANGE_EMPLOYER_ADMIN_STATUS,
    GET_EMPLOYER_ADMIN_BY_ID,
    LOADING_ON_EMPLOYER_ADMIN_SUBMIT,
    LOADING_EMPLOYER_ADMIN_LIST_DATA,
    EMPLOYER_ADMIN_SEARCH_PARAMATERS_UPDATE,
    EMPLOYER_ADMIN_LIST_UPDATED,
    EMPLOYER_LIST_BY_COMPANY_ADMIN_UPDATED,
    RESET,
    LOAD_PAGE,
    EMPLOYER_IDS_LIST_UPDATED,
  } from "actions/types";
  import * as Constants from "constants/index";

  const initialState = {
    currentEmployerAdmin: null,
    performDelete: false,
    loadingEmployerAdmin: false,
    loadingListData: false,
    loadingEmployerAdminList: false,
    employerIdList: [],
    employerListByCompanyAdmin: [],
    error: {},
    employerAdminList: {
      page: 1,
      data: [],
      count: 0
    },
    sortingParams: {
      limit: Constants.DEFAULT_PAGE_SIZE,
      page: 1,
      orderBy: "created_at",
      ascending: "desc",
      query: "",
      filters:[],
    },
  };

  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return {
          ...initialState,
          loadingEmployerAdmin: state.loadingEmployerAdmin,
          loadingListData: state.loadingListData,
          loadingEmployerAdminList: state.loadingEmployerAdminList,
          performDelete: false,
        }
      case LOAD_PAGE:
        return {
          ...state,
          loadingEmployerAdmin: false
        };
      case EMPLOYER_ADMIN_CREATED:
        return {
          ...state,
          loadingEmployerAdmin: false
        };
      case EMPLOYER_ADMIN_UPDATED:
        return {
          ...state,
          currentEmployerAdmin: payload,
          sortingParams: initialState.sortingParams,
          loadingEmployerAdmin: false,
        };
      case EMPLOYER_ADMIN_ERROR:
        return {
          ...state,
          error: payload,
          performDelete: false,
          loadingEmployerAdmin: false,
          loadingEmployerAdminList: false,
          loadingListData: false,
        };
      case EMPLOYER_ADMIN_DELETE:
        const currentCount = state.employerAdminList.count;
        const currentLimit = state.sortingParams.limit;
        const currentPage = parseInt(state.employerAdminList.page);
        let remainingPages = Math.ceil((currentCount - 1)/currentLimit);
        remainingPages = remainingPages?remainingPages:1;
        return {
          ...state,
          employerAdminList: {
            data: state.employerAdminList.data.filter(admin =>
              admin._id !== payload
            ),
            count: currentCount - 1,
            page: currentPage <= remainingPages?currentPage.toString(): remainingPages.toString(),
          },
          loadingEmployerAdminList: false,
          performDelete: true,
        };
      case CHANGE_EMPLOYER_ADMIN_STATUS:
        return {
          ...state,
          employerAdminList: {
            ...state.employerAdminList,
            data: state.employerAdminList.data.map(admin =>
              admin._id === payload._id
                ? { ...admin, status: payload.status }
                : admin
            )
          }
        };
      case GET_EMPLOYER_ADMIN_BY_ID:
        return {
          ...state,
          currentEmployerAdmin: payload,
          loadingEmployerAdmin: false,
        };
      case EMPLOYER_ADMIN_SEARCH_PARAMATERS_UPDATE:
        return {
          ...state,
          sortingParams: { ...payload }
        };
      case EMPLOYER_LIST_BY_COMPANY_ADMIN_UPDATED:
        return {
          ...state,
          employerListByCompanyAdmin: payload,
          loadingEmployerAdminList: false,
          loadingListData: false,
          performDelete: false,
        };
      case EMPLOYER_IDS_LIST_UPDATED:
        return {
          ...state,
          employerIdList: payload,
          loadingEmployerAdminList: false,
          loadingListData: false,
          performDelete: false,
        };
      case EMPLOYER_ADMIN_LIST_UPDATED:
        return {
          ...state,
          employerAdminList: {
            data: payload.data,
            page: payload.metadata[0].current_page,
            count: payload.metadata[0].totalRecord
          },
          loadingEmployerAdminList: false,
          loadingListData: false,
        };
      case LOADING_ON_EMPLOYER_ADMIN_SUBMIT:
        return {
          ...state,
          loadingEmployerAdmin: true
        }
      case LOADING_EMPLOYER_ADMIN_LIST_DATA:
        return {
          ...state,
          loadingListData: true
        };
      default:
        return state;
    }
  }
