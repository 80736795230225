import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "actions/auth";

const Header = ({ auth: { isAuthenticated, loading, user }, logout, history }) => {
  var isAdmin = false;
  if (user !== null) {
    if(user.role) isAdmin = true;
    history.push('/admin');
  }
  const authLinks = (
    <ul>
      {/* <li>
        <Link to="/profiles">Developers</Link>
      </li> */}
      {/* <li>
        <Link to="/posts">Posts</Link>
      </li> */}
      <li>
        <Link to={isAdmin ? "/admin" : "/"}>
          <i className="fa fa-user"></i>{" "}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      <li>
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt"></i>{" "}
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      {/* <li>
        <Link to='/profiles'>Developers</Link>
      </li> */}
      {/* <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/login">Login</Link>
      </li> */}
    </ul>
  );

  return (
    <nav className="bg-dark">
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
    </nav>
  );
};
Header.prototype = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Header);
