import {
  UPDATE_BUSINESS_TYPES,
  UPDATE_BUSINESS_CATEGORIES,
} from "actions/types";

const initialState = {
  typesOfBusinessGlobal: [],
  categoriesOfBusinessGlobal: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_BUSINESS_TYPES:
      return {
        ...state,
        typesOfBusinessGlobal: payload,
      };
    case UPDATE_BUSINESS_CATEGORIES:
      return {
        ...state,
        categoriesOfBusinessGlobal: payload,
      };

    default:
      return state;
  }
}
