import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_LINK_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  AUTH_TOKEN_REFRESH,
  // USER_AUTHENTICATED,
  OTP_SENT_SUCCESS,
  LOGIN_OTP_SENT_SUCCESS,
  ENABLE_RESEND_OTP_OPTION,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_SUCCESS,
  LOADING_ON_LOGIN_SUBMIT,
  LOADING_ON_PASSWORD_RESET,
  LOGIN_FAIL,
  LOAD_AUTH_PAGE,
  PENDING_ACTION_LOADED,
} from "actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  authId: false,
  loading: true,
  loadingPasswordReset: false,
  user: null,
  otpSentForLogin: false,
  enableResendOTPOption: false,
  //email:'',
  error: {},
  actionPending: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log(type);
  switch (type) {
    case LOAD_AUTH_PAGE:
      return {
        ...state,
        loading: false
      };
    case AUTH_TOKEN_REFRESH:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      };
    case REGISTER_SUCCESS:
    case RESET_LINK_SUCCESS:
      return {
        ...state,
        loadingPasswordReset: false,
      };
    case OTP_SENT_SUCCESS:
      return {
        ...state,
        authId: true,
        loading: false,
      };
    case LOGIN_OTP_SENT_SUCCESS:
      return {
        ...state,
        otpSentForLogin: true,
        loading: false,
        enableResendOTPOption: false,
      };

    case ENABLE_RESEND_OTP_OPTION:
      return {
        ...state,
        enableResendOTPOption: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        ...payload,
        authId: false,
        otpSentForLogin: false,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
    case RESET_LINK_FAIL:
      return {
        ...state,
        error: payload,
        loadingPasswordReset: false,
      };
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        error: payload,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case LOGOUT:
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case LOADING_ON_PASSWORD_RESET:
      return {
        ...state,
        loadingPasswordReset: true,
      }
    case LOADING_ON_LOGIN_SUBMIT:
      return {
        ...state,
        loading: true
      }
    case PENDING_ACTION_LOADED:
      return {
        ...state,
        actionPending: payload
      }
    default:
      return state;
  }
}
