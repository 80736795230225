import {
  ADMIN_RECURRING_ORDER_CREATED,
  ADMIN_RECURRING_ORDER_ERROR,
  ADMIN_RECURRING_ORDER_DELETE,
  ADMIN_RECURRING_ORDER_UPDATED,
  ADMIN_RECURRING_ORDER_LIST_UPDATED,
  ADMIN_RECURRING_ORDER_GET_BY_ID,
  ADMIN_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE,
  ADMIN_RECURRING_ORDER_CHANGE_STATUS,
  ADMIN_RECURRING_ORDER_LOADING_ON_SUBMIT,
  RESET,
  LOAD_PAGE,
  ADMIN_RECURRING_ORDER_LOADING_LIST_DATA,
  ADMIN_RECURRING_ORDER_CANCEL_SUBMIT,
  ADMIN_RECURRING_ORDER_CANCEL_COMPLETE,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  recurringOrdersList: {
    page: 1,
    data: [],
    count: 0
  },
  employersList: [],
  employeesList: [],
  currentRecurringOrder: null,
  loadingRecurringOrderList: true,
  loadingRecurringOrder: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters:[],
  },
  loadingCancelRecurringOrder: false,
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingRecurringOrder: state.loadingRecurringOrder,
        loadingRecurringOrderList: state.loadingRecurringOrderList,
        loadingListData: state.loadingListData,
      }
    case LOAD_PAGE:
      return {
        ...state,
        loadingRecurringOrder: false
      };
    case ADMIN_RECURRING_ORDER_CREATED:
      return {
        ...state,
        loadingRecurringOrder: false
      };
    case ADMIN_RECURRING_ORDER_UPDATED:
      return {
        ...state,
        currentRecurringOrder: payload,
        sortingParams: initialState.sortingParams,
        loadingRecurringOrder: false,
      };
    case ADMIN_RECURRING_ORDER_DELETE:
      const currentCount = state.recurringOrdersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.recurringOrdersList.page);
      let remainingPages = Math.ceil((currentCount - 1)/currentLimit);
    remainingPages = remainingPages?remainingPages:1;
      return {
        ...state,
        recurringOrdersList: {
          data: state.recurringOrdersList.data.filter(testOrder =>
            testOrder._id !== payload
          ),
          count: currentCount - 1,
          page: currentPage <= remainingPages?currentPage.toString(): remainingPages.toString(),
        },
        loadingRecurringOrderList: false,
        performDelete: true,
      };
    case ADMIN_RECURRING_ORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingRecurringOrder: false,
        loadingRecurringOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_RECURRING_ORDER_GET_BY_ID:
      return {
        ...state,
        currentRecurringOrder: payload,
        loadingRecurringOrder: false,
      };
    case ADMIN_RECURRING_ORDER_LIST_UPDATED:
      return {
        ...state,
        recurringOrdersList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord
        },
        // loadingRecurringOrder: true,
        loadingRecurringOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case ADMIN_RECURRING_ORDER_CHANGE_STATUS:
      return {
        ...state,
        recurringOrdersList: {
          ...state.recurringOrdersList,
          data: state.recurringOrdersList.data.map(testOrder =>
            testOrder._id === payload._id
              ? { ...testOrder, status: payload.status }
              : testOrder
          )
        }
      };
    case ADMIN_RECURRING_ORDER_LOADING_LIST_DATA:
      return {
        ...state,
        loadingListData: true
      };
    case ADMIN_RECURRING_ORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingRecurringOrder: true
      };
    case ADMIN_RECURRING_ORDER_CANCEL_SUBMIT:
      return {
        ...state,
        loadingCancelRecurringOrder: true
      };
    case ADMIN_RECURRING_ORDER_CANCEL_COMPLETE:
      return {
        ...state,
        loadingCancelRecurringOrder: false
      };
    default:
      return state;
  }
}
