import {
  ADMIN_PRACTICE_CREATED,
  ADMIN_PRACTICE_ERROR,
  ADMIN_PRACTICE_DELETE,
  ADMIN_PRACTICE_UPDATED,
  ADMIN_PRACTICE_LIST_UPDATED,
  ADMIN_PRACTICE_GET_NAMES_LIST,
  ADMIN_PRACTICE_GET_BY_ID,
  ADMIN_PRACTICE_SEARCH_PARAMATERS_UPDATE,
  ADMIN_PRACTICE_CHANGE_STATUS,
  ADMIN_PRACTICE_LOADING_ON_SUBMIT,
  RESET,
  LOAD_PAGE,
  ADMIN_PRACTICE_LOADING_LIST_DATA,
  ADMIN_PRACTICE_NAMES_LOADING_LIST_DATA,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  practicesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPractice: null,
  loadingPracticeList: true,
  loadingPractice: true,
  loadingListData: true,
  allPracticesNames: [],
  loadingNamesListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return {
        ...initialState,
        loadingPractice: state.loadingPractice,
        loadingPracticeList: state.loadingPracticeList,
        loadingListData: state.loadingListData,
        loadingNamesListData: state.loadingNamesListData,
      };
    case LOAD_PAGE:
      return {
        ...state,
        loadingPractice: false,
      };
    case ADMIN_PRACTICE_CREATED:
      return {
        ...state,
        loadingPractice: false,
      };
    case ADMIN_PRACTICE_UPDATED:
      return {
        ...state,
        currentPractice: payload,
        sortingParams: initialState.sortingParams,
        loadingPractice: false,
      };
    case ADMIN_PRACTICE_DELETE:
      const currentCount = state.practicesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.practicesList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        practicesList: {
          data: state.practicesList.data.filter(
            (practice) => practice._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingPracticeList: false,
        performDelete: true,
      };
    case ADMIN_PRACTICE_ERROR:
      return {
        ...state,
        error: payload,
        loadingPractice: false,
        loadingListData: false,
        performDelete: false,
        loadingNamesListData: false,
      };
    case ADMIN_PRACTICE_GET_BY_ID:
      return {
        ...state,
        currentPractice: payload,
        loadingPractice: false,
      };
    case ADMIN_PRACTICE_GET_NAMES_LIST:
      return {
        ...state,
        allPracticesNames: payload,
        loadingNamesListData: false,
      };
    case ADMIN_PRACTICE_LIST_UPDATED:
      return {
        ...state,
        practicesList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        // loadingPractice: true,
        loadingPracticeList: false,
        loadingListData: false,
        performDelete: false,
      };
    case ADMIN_PRACTICE_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case ADMIN_PRACTICE_CHANGE_STATUS:
      return {
        ...state,
        loadingListData: false,
        practicesList: {
          ...state.practicesList,
          data: state.practicesList.data.map((practice) =>
            practice._id === payload._id
              ? { ...practice, status: payload.status }
              : practice
          ),
        },
      };
    case ADMIN_PRACTICE_LOADING_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case ADMIN_PRACTICE_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingPractice: true,
      };
    case ADMIN_PRACTICE_NAMES_LOADING_LIST_DATA:
      return {
        ...state,
        loadingNamesListData: true,
      };
    default:
      return state;
  }
}
