import {
  USER_UPDATE_USER_PROFILE,
  USER_LOAD_USER_PROFILE,
  USER_PROFILE_UPLOAD_ERROR,
  USER_PROFILE_UPLOADING,
} from "actions/types";

const initialState = {
  loadingUserProfile: false,
  error: {},
  userProfile: null,
  profileError: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: payload,
        loadingUserProfile: false,
      };
    case USER_LOAD_USER_PROFILE:
      return {
        ...state,
        loadingUserProfile: true,
      };
    case USER_PROFILE_UPLOADING: {
      return {
        ...state,
        profileError: null,
      };
    }
    case USER_PROFILE_UPLOAD_ERROR: {
      return {
        ...state,
        profileError: payload,
      };
    }
    default:
      return state;
  }
}
